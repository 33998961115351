import { inject, Injectable, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { BehaviorSubject, combineLatest, Observable, ReplaySubject, Subject } from "rxjs";
import { filter, map, pairwise, takeUntil } from "rxjs/operators";
import { capitalize } from "lodash";

import { LgTranslateService } from "@logex/framework/lg-localization";

import {
    SharedColoringService,
    SharedConfigService,
} from "@codman/shared/util-logex-framework-setup";
import { allAllowedUrlParams, comparisonUrlParam, connectUrlParam } from "@codman/shared/types";
import { IComparisonType, IMultiDataSelection } from "@codman/shared/feature-benchmarking";

import {
    IBenchmarkConfiguration,
    Benchmark,
    INITIAL_BENCHMARK,
    Regions,
    IRegionalBenchmark,
    IConnectBenchmark,
    IProviderTypeBenchmark,
    Provider,
    providersForUrl,
    IRegistryConfiguration,
} from "./feature-benchmarking.types";

export const defaultProviders: Provider[] = ["ownProvider", "nationalBenchmark"];
type ColorsOrderedOption = "darkest" | "dark" | "medium" | "light" | "lightest";

@Injectable({
    providedIn: "root",
})
export class FeatureBenchmarkingService implements OnDestroy {
    private _coloringService = inject(SharedColoringService);
    private _lgTranslateService = inject(LgTranslateService);
    private _sharedConfigService = inject(SharedConfigService);
    private _router = inject(Router);
    private _activatedRoute = inject(ActivatedRoute);

    readonly colors: { [key: string]: string } = {
        ownProvider: this._coloringService.getTheme().provider,
        benchmark: this._coloringService.getTheme().benchmark,
        national: this._coloringService.getTheme().benchmark,
        nationalBenchmark: this._coloringService.getTheme().benchmark,
        regional: this._coloringService.getTheme().benchmark,
        international: this._coloringService.getTheme().benchmark,
        internationalBenchmark: this._coloringService.getTheme().benchmark,
        connect: this._coloringService.getTheme().benchmarkConnect,
        connectBenchmark: this._coloringService.getTheme().benchmarkConnect,
        sharedOrganization: this._coloringService.getTheme().sharedOrganization,
        providerType: this._coloringService.getTheme().benchmarkProviderType,
        providerTypeBenchmark: this._coloringService.getTheme().benchmarkProviderType,
    };

    readonly colorsOrdered: { [key in ColorsOrderedOption]: string }[] = [
        {
            darkest: "#004B51",
            dark: "#00676E",
            medium: "#00A3AD",
            light: "#00C3CE",
            lightest: "#C2F5FB",
        },
        {
            darkest: "#003D9B",
            dark: "#0054D0",
            medium: "#6A8DFF",
            light: "#C1CBFF",
            lightest: "#E8EBFF",
        },
        {
            darkest: "#712369",
            dark: "#98328E",
            medium: "#E75AD9",
            light: "#F4BCED",
            lightest: "#FBE6F8",
        },
        {
            darkest: "#7C551A",
            dark: "#9E6E25",
            medium: "#E7A33A",
            light: "#FBC388",
            lightest: "#FDE9D8",
        },
    ];

    /**
     * Benchmark configuration
     */
    private readonly _benchmarkConfiguration$ = new ReplaySubject<IBenchmarkConfiguration>();

    readonly availableConnectProviders$ = this._benchmarkConfiguration$.pipe(
        map(benchmarkConfiguration => benchmarkConfiguration.connect),
    );

    readonly benchmarkDimension$ = this._benchmarkConfiguration$.pipe(
        map(benchmarkConfiguration => benchmarkConfiguration.benchmarkDimension),
    );

    /**
     * Benchmark selection
     */
    private readonly _selectedBenchmark$ = new BehaviorSubject<Benchmark>(
        this.getInitialBenchmark(),
    );

    private getInitialBenchmark(): Benchmark {
        return INITIAL_BENCHMARK;
    }

    readonly selectedBenchmark$ = combineLatest([
        this._selectedBenchmark$,
        this._benchmarkConfiguration$,
    ]).pipe(
        map(([selectedBenchmark, configuration]) => {
            const isSelectionValid = this._isBenchmarkSelectionValid(
                selectedBenchmark,
                configuration,
            );
            if (isSelectionValid) {
                return selectedBenchmark;
            }
            return this._getDefaultBenchmark(configuration);
        }),
    );

    private readonly _selectedProviders$ = new BehaviorSubject<Provider[]>([...defaultProviders]);
    readonly selectedProviders$ = this._selectedProviders$.asObservable();

    private readonly _selectedProvidersMultiData$ = new BehaviorSubject<IMultiDataSelection>({
        provider: "ownProvider",
        dimensions: [],
    });
    readonly selectedProvidersMultiData$ = this._selectedProvidersMultiData$.asObservable();

    private readonly _comparisonType$ = new BehaviorSubject<IComparisonType>("basic");
    readonly comparisonType$ = this._comparisonType$.asObservable();

    readonly selectedConnectProviders$ = combineLatest([
        this.selectedBenchmark$,
        this.selectedProviders$,
        this.selectedProvidersMultiData$,
        this.comparisonType$,
    ]).pipe(
        map(([benchmark, providers, providersMultiData, comparisonType]) => {
            return benchmark.type === "connect" &&
                (comparisonType === "multiData"
                    ? providersMultiData.provider === "connectBenchmark"
                    : providers.includes("connectBenchmark"))
                ? benchmark.connectProviders
                : undefined;
        }),
    );

    readonly selectedProviderType$ = this.selectedBenchmark$.pipe(
        map(benchmark => (benchmark.type === "providerType" ? benchmark.providerType : undefined)),
        // filter((providerType): providerType is string => providerType != null),
    );

    readonly selectedRegions$ = this.selectedBenchmark$.pipe(
        map(benchmark =>
            benchmark.type === "regional" ||
            benchmark.type === "connect" ||
            benchmark.type === "providerType"
                ? benchmark.regions
                : undefined,
        ),
        filter((regions): regions is Regions => regions != null),
    );

    readonly selectedBenchmarkColor$ = this.selectedBenchmark$.pipe(
        map(benchmark => {
            return this.colors[benchmark.type];
        }),
    );

    readonly selectedBenchmarkLabel$ = this.selectedBenchmark$.pipe(
        map(benchmark => this._getSelectedBenchmarkLabel(benchmark)),
    );

    private readonly _destroyed$ = new Subject<void>();

    constructor() {
        this._resetConnectProvidersWhenAvailableProvidersChange();
    }

    ngOnDestroy(): void {
        this._destroyed$.next();
        this._destroyed$.complete();
    }

    initialize(
        becnhmarkConfiguration$: Observable<IBenchmarkConfiguration>,
        registryConfiguration$?: Observable<IRegistryConfiguration>,
    ): void {
        becnhmarkConfiguration$.subscribe(configuration => {
            this._benchmarkConfiguration$.next(configuration);
        });

        if (registryConfiguration$)
            combineLatest([
                this._activatedRoute.queryParamMap,
                registryConfiguration$,
                becnhmarkConfiguration$,
                this.availableConnectProviders$,
            ])
                .pipe(takeUntil(this._destroyed$))
                .subscribe(
                    ([
                        routeParams,
                        registryConfiguration,
                        benchmarkConfiguration,
                        allAllowedConnectProviders,
                    ]) => {
                        const comparison = routeParams.get(comparisonUrlParam);
                        let selectedProviders: Provider[] = [];
                        let selectedConnectIds: number[] = [];

                        if (comparison) {
                            // setting selected sources ("comparison=a,b" in url)
                            selectedProviders = comparison
                                .split(",")
                                .map(provider => {
                                    let foundProvider = providersForUrl.find(
                                        providerForUrl => providerForUrl.urlParam === provider,
                                    )?.provider;

                                    if (
                                        foundProvider === "ownProvider" ||
                                        (foundProvider === "nationalBenchmark" &&
                                            (registryConfiguration.benchmarkTypes.includes(
                                                "national",
                                            ) ||
                                                registryConfiguration.benchmarkTypes.includes(
                                                    "regional",
                                                )))
                                    )
                                        return foundProvider;

                                    if (
                                        foundProvider === "connectBenchmark" &&
                                        registryConfiguration.benchmarkTypes.includes("connect") &&
                                        benchmarkConfiguration.connect.length > 0
                                    )
                                        return foundProvider;

                                    return undefined;
                                })
                                .filter(provider => provider != undefined);

                            // setting connect provider ids ("connect=1,2" in url)
                            const connect = routeParams.get(connectUrlParam);
                            if (
                                selectedProviders.includes("connectBenchmark") &&
                                registryConfiguration.benchmarkTypes.includes("connect")
                            ) {
                                if (connect?.split(",").map(Number))
                                    selectedConnectIds = connect?.split(",").map(Number);

                                const canUseCurrentProviders =
                                    selectedConnectIds.length &&
                                    selectedConnectIds.every(currentProvider =>
                                        allAllowedConnectProviders.find(
                                            provider => provider.id === currentProvider,
                                        ),
                                    ) &&
                                    selectedConnectIds.every(currentProvider =>
                                        benchmarkConfiguration.connect.find(
                                            provider => provider.id === currentProvider,
                                        ),
                                    );

                                if (
                                    benchmarkConfiguration.connect.length > 0 &&
                                    !canUseCurrentProviders
                                ) {
                                    selectedConnectIds = allAllowedConnectProviders.map(
                                        provider => provider.id,
                                    );
                                }
                            } else if (selectedProviders.includes("connectBenchmark")) {
                                selectedProviders = selectedProviders.filter(
                                    item => item !== "connectBenchmark",
                                );
                            }
                        }

                        // setting regions
                        let regionsSelection: Regions;
                        if (
                            benchmarkConfiguration.defaultRegion &&
                            benchmarkConfiguration.regions.includes(
                                benchmarkConfiguration.defaultRegion,
                            )
                        ) {
                            regionsSelection = [benchmarkConfiguration.defaultRegion];
                        } else {
                            const [firstRegion, ...otherRegions] = benchmarkConfiguration.regions;
                            regionsSelection =
                                firstRegion != null ? [firstRegion, ...otherRegions] : ["nl"];
                        }

                        // updating selected providers and url content
                        const currentProviders =
                            selectedProviders.length < 2 ? defaultProviders : selectedProviders;
                        this.updateSelectedProviders(
                            currentProviders,
                            selectedProviders.length === 0,
                            selectedConnectIds,
                            selectedConnectIds.length === 0,
                        );

                        // updating connect id selection
                        if (selectedConnectIds.length > 0)
                            this.updateSelectedBenchmark({
                                type: "connect",
                                connectProviders: selectedConnectIds,
                                regions: regionsSelection,
                            });
                    },
                );
    }

    updateSelectedBenchmark(selectedBenchmark: Benchmark, multiData?: boolean): void {
        this._selectedBenchmark$.next(selectedBenchmark);
        this._comparisonType$.next(multiData ? "multiData" : "basic");
    }

    resetSelectedBenchmark(): void {
        this._selectedBenchmark$.next(this.getInitialBenchmark());
    }

    updateSelectedProviders(
        selectedProviders: Provider[],
        keepCurrentProviders = true,
        connectProviders: number[] = [],
        keepCurrentConnectIds = true,
    ): void {
        const currentProviders = keepCurrentProviders
            ? this._selectedProviders$.value
            : selectedProviders;
        const currentConnect = keepCurrentConnectIds
            ? (this._selectedBenchmark$.value as IConnectBenchmark).connectProviders
            : connectProviders;
        this._addProvidersAndConnectIdsToUrl(
            currentProviders,
            currentProviders.includes("connectBenchmark") ? currentConnect : [],
        );
        this._selectedProviders$.next(selectedProviders);
    }

    updateSelectedProvidersMultiData(selectedProviderMultidata: IMultiDataSelection): void {
        this._selectedProvidersMultiData$.next(selectedProviderMultidata);
        this._comparisonType$.next("multiData");
    }

    resetSelectedProviders(): void {
        this._selectedProviders$.next([...defaultProviders]);
    }

    removeProvider(provider: Provider): void {
        const updatedProviders = this._selectedProviders$.value.filter(
            selectedProvider => selectedProvider !== provider,
        );
        this._addProvidersAndConnectIdsToUrl(
            updatedProviders,
            provider === "connectBenchmark"
                ? []
                : ((this._selectedBenchmark$.value as IConnectBenchmark).connectProviders ?? []),
        );
        this._selectedProviders$.next(updatedProviders);
    }

    removeMultiDataDimension(dimension: string): void {
        const updatedProviders = this._selectedProvidersMultiData$.value.dimensions.filter(
            selectedDimension => selectedDimension !== dimension,
        );
        this._selectedProvidersMultiData$.next({
            provider: this._selectedProvidersMultiData$.value.provider,
            dimensions: updatedProviders,
        });
    }

    private _addProvidersAndConnectIdsToUrl(
        selectedProviders: Provider[],
        connectProviders: number[],
    ): void {
        let queryParams: { [key: string]: string } = {
            ...this._activatedRoute.snapshot.queryParams,
        };
        let changed = false;

        const providers = selectedProviders.map(provider => {
            return providersForUrl.find(providerForUrl => providerForUrl.provider === provider)
                ?.urlParam;
        });
        const providersUrlValue = `${providers.join(",")}`;
        if (
            queryParams[comparisonUrlParam] === undefined ||
            queryParams[comparisonUrlParam] !== providersUrlValue
        ) {
            queryParams[comparisonUrlParam] = providersUrlValue;
            changed = true;
        }

        const connectUrlValue = connectProviders ? `${connectProviders.join(",")}` : undefined;
        if (
            connectProviders?.length > 0 &&
            connectUrlValue &&
            (queryParams[connectUrlParam] === undefined ||
                queryParams[connectUrlParam] !== connectUrlValue)
        ) {
            queryParams[connectUrlParam] = connectUrlValue;
            changed = true;
        } else if (connectProviders?.length === 0 && queryParams[connectUrlParam] !== undefined) {
            delete queryParams[connectUrlParam];
            changed = true;
        }

        for (const key in queryParams) {
            if (!allAllowedUrlParams.includes(key) || queryParams[key] === undefined) {
                delete queryParams[key];
                changed = true;
            }
        }

        if (changed)
            this._router.navigate([], {
                queryParams,
            });
    }

    addCurrentProvidersToUrl(): void {
        this._addProvidersAndConnectIdsToUrl(
            this._selectedProviders$.value,
            (this._selectedBenchmark$.value as IConnectBenchmark).connectProviders ?? [],
        );
    }

    getIndicatorsColors(): { [key: string]: string } {
        return {
            ownProvider: this.colors["ownProvider"],
            benchmark: this.colors["national"],
            connectBenchmark: this.colors["connect"],
            providerTypeBenchmark: this.colors["providerType"],
        };
    }

    getExplorationColors(): { [key: string]: string } {
        return {
            ownProvider: this.colors["ownProvider"],
            nationalBenchmark: this.colors["national"],
            internationalBenchmark: this.colors["international"],
            connectBenchmark: this.colors["connect"],
            sharedOrganization: this.colors["sharedOrganization"],
            providerTypeBenchmark: this.colors["providerType"],
        };
    }

    private _isBenchmarkSelectionValid(
        selectedBenchmark: Benchmark,
        configuration: IBenchmarkConfiguration,
    ): boolean {
        switch (selectedBenchmark.type) {
            case "regional":
                return this._validateRegion(selectedBenchmark, configuration);
            case "connect":
                return this._validateConnect(selectedBenchmark, configuration);
            case "providerType":
                return this._validateProviderType(selectedBenchmark, configuration);
            default:
                console.warn("Using a deprecated benchmark type");
                return true;
        }
    }

    private _validateRegion(
        selectedBenchmark: IRegionalBenchmark,
        configuration: IBenchmarkConfiguration,
    ): boolean {
        return selectedBenchmark.regions.every(selectedRegion =>
            configuration.regions.find(availableRegion => availableRegion === selectedRegion),
        );
    }

    private _validateConnect(
        selectedBenchmark: IConnectBenchmark,
        configuration: IBenchmarkConfiguration,
    ): boolean {
        return (
            selectedBenchmark.connectProviders.length > 0 &&
            selectedBenchmark.connectProviders.every(selectedProvider =>
                configuration.connect.find(
                    availableProvider => availableProvider.id === selectedProvider,
                ),
            )
        );
    }

    private _validateProviderType(
        selectedBenchmark: IProviderTypeBenchmark,
        configuration: IBenchmarkConfiguration,
    ): boolean {
        return configuration.providerTypes.includes(selectedBenchmark.providerType);
    }

    private _getDefaultBenchmark({ defaultRegion, regions }: IBenchmarkConfiguration): Benchmark {
        let regionsSelection: Regions;
        if (defaultRegion && regions.includes(defaultRegion)) {
            regionsSelection = [defaultRegion];
        } else {
            const [firstRegion, ...otherRegions] = regions;
            regionsSelection = firstRegion != null ? [firstRegion, ...otherRegions] : ["nl"];
        }
        return {
            type: "regional",
            regions: regionsSelection,
        };
    }

    private _getSelectedBenchmarkLabel(benchmark: Benchmark): string {
        if (benchmark.type === "connect") {
            return "APP._Shared.ConnectBenchmark";
        } else if (benchmark.type === "regional") {
            return this._getBenchmarkRegionalLabel(benchmark.regions);
        } else if (benchmark.type === "providerType") {
            return this._lgTranslateService.translate(
                `APP._Shared.BenchmarkSelector.Benchmarks.Providertype.Providers.${benchmark.providerType}.Abbreviation`,
            );
            // return "APP._Shared.ProviderTypeBenchmark";
        }
        return `APP._Shared.BenchmarkSelector.Benchmarks.${capitalize(benchmark.type)}.ShortLabel`;
    }

    private _getBenchmarkRegionalLabel(regions: string[]): string {
        const benchmarkLabel = this._lgTranslateService.translate(
            "APP._Shared.BenchmarkSelector.Benchmark",
        );

        if (regions.length === 1) {
            return `${regions[0].toUpperCase()} ${benchmarkLabel}`;
        }

        const regionsList = regions.map(region => region.toUpperCase()).join(", ");
        return `${benchmarkLabel} ${regionsList}`;
    }

    private _resetConnectProvidersWhenAvailableProvidersChange(): void {
        this.availableConnectProviders$
            .pipe(pairwise())
            .subscribe(([previousProviders, currentProviders]) => {
                const previousProviderIds = previousProviders.map(provider => provider.id).sort();
                const currentProviderIds = currentProviders.map(provider => provider.id).sort();
                if (JSON.stringify(previousProviderIds) !== JSON.stringify(currentProviderIds)) {
                    this.resetSelectedBenchmark();
                }
            });
    }
}
