import { Injectable, inject } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";

import { ReplaySubject, Observable, combineLatest, of, BehaviorSubject } from "rxjs";
import {
    distinctUntilChanged,
    shareReplay,
    switchMap,
    map,
    first,
    filter,
    startWith,
    withLatestFrom,
    take,
    combineLatestWith,
} from "rxjs/operators";
import { upperFirst } from "lodash";

import { LgTranslateService } from "@logex/framework/lg-localization";

import {
    AuthRegistryInfo,
    CodmanUserAuthorizationService,
    TENANT_LOOKUP,
} from "@codman/shared/data-access-authorization";
import { CodmanEventTracer } from "@codman/shared/util-tracking";
import {
    FeatureBenchmarkingService,
    IRegistryConfiguration,
} from "@codman/shared/feature-benchmarking";
import { ComparisonPopupInfoClickEvent } from "@codman/shared/feature-comparison-picker";

import { UiTrendDialog } from "@codman/exploration/feature-trend-dialog";
import { PatientsListDialog } from "@codman/exploration/feature-patients-list-dialog";

import { DataAccessApiService } from "./data-access-api.service";
import {
    IRegistryStructure,
    TimeRangeFilterRange,
    ISubsetConfiguration,
    OutcomesConfiguration,
    IBenchmarkConfiguration,
    IOutcomeUniversal,
    OutcomeConfiguration,
    Unit,
    CalculationType,
    MedicalItem,
    OutcomeWithConfigurationUniversal,
    BenchmarkParams,
    ITrendMoment,
    PatientList,
    IPatientCount,
    AnalysisUnit,
    ActiveFilters,
    FilterSplits,
    FilterParams,
    FilterParam,
} from "./data-access-api.types";
import { EMPTY_PARAMETER } from "libs/exploration/util-translating/src/lib/outcome-label.pipe";

export interface IRegistryStructureLookup {
    [subset: string]: {
        [page: string]: string[];
    };
}

export interface NavigationState {
    registryId: string;
    subsetId: string;
}

export interface FullNavigationState extends NavigationState {
    pageId: string;
}

export const EMPTY_UNIT = "APP._Shared.Empty";

@Injectable({
    providedIn: "root",
})
export class DataAccessStateService {
    private _dataAccessApiService = inject(DataAccessApiService);
    private _router = inject(Router);
    private _authorizationService = inject(CodmanUserAuthorizationService);
    private _tracer = inject(CodmanEventTracer);
    private _benchmarkingService = inject(FeatureBenchmarkingService);
    private _lgTranslateService = inject(LgTranslateService);

    private _currentRegistry$ = new ReplaySubject<string>(1);
    readonly currentRegistry$ = this._currentRegistry$.pipe(distinctUntilChanged());

    private _currentSubset$ = new ReplaySubject<string>(1);
    readonly currentSubset$ = this._currentSubset$.pipe(distinctUntilChanged());

    private _currentPage$ = new ReplaySubject<string>(1);
    readonly currentPage$ = this._currentPage$.pipe();

    private _expandedOutcomesSections: string[] = [];

    readonly navigationState$: Observable<NavigationState> = this.currentSubset$.pipe(
        withLatestFrom(this.currentRegistry$),
        map(([subsetId, registryId]) => ({
            registryId,
            subsetId,
        })),
        shareReplay(1),
    );

    readonly fullNavigationState$: Observable<FullNavigationState> = this.currentPage$.pipe(
        withLatestFrom(this.navigationState$),
        map(([pageId, { registryId, subsetId }]) => ({
            registryId,
            subsetId,
            pageId,
        })),
        shareReplay(1),
    );

    /**
     * Converted API data to Hot Observables
     */
    readonly registryStructure$ = combineLatest([
        this.currentRegistry$,
        this._authorizationService.organizationId$,
    ]).pipe(
        switchMap(([currentRegistryId, organizationId]) =>
            this._dataAccessApiService
                .getRegistryStructure(currentRegistryId, organizationId)
                .pipe(startWith(null)),
        ),
        shareReplay(1),
        filter((structure): structure is IRegistryStructure => structure != null),
    );

    readonly registryConfiguration$ = combineLatest([
        this.currentRegistry$,
        this._authorizationService.organizationId$,
    ]).pipe(
        switchMap(([currentRegistryId, organizationId]) =>
            this._dataAccessApiService
                .getRegistryConfiguration(currentRegistryId, organizationId)
                .pipe(startWith(null)),
        ),
        shareReplay(1),
        filter((configuration): configuration is IRegistryConfiguration => configuration != null),
    );

    readonly benchmarkConfiguration$ = combineLatest([
        this.currentRegistry$,
        this._authorizationService.organizationId$,
    ]).pipe(
        switchMap(([registryId, organizationId]) =>
            this._dataAccessApiService
                .getBenchmarkConfiguration(registryId, organizationId)
                .pipe(startWith(null)),
        ),
        shareReplay(1),
        filter((configuration): configuration is IBenchmarkConfiguration => configuration != null),
    );

    readonly registryStructureLookup$ = this.registryStructure$.pipe(
        map(structure => this._getRegistryStructureLookup(structure)),
    );

    readonly subsetConfiguration$ = this.navigationState$.pipe(
        combineLatestWith(this._authorizationService.organizationId$),
        switchMap(([{ registryId, subsetId }, organizationId]) =>
            this._dataAccessApiService
                .getSubsetConfiguration(registryId, subsetId, organizationId)
                .pipe(startWith(null)),
        ),
        shareReplay(1),
        filter((configuration): configuration is ISubsetConfiguration => configuration != null),
    );

    readonly medicalParameters$ = this.subsetConfiguration$.pipe(
        map(({ medicalParameters }) => medicalParameters),
    );

    readonly filters$ = this.subsetConfiguration$.pipe(map(({ filterGroups }) => filterGroups));

    readonly yearFilter$: Observable<TimeRangeFilterRange>;

    readonly outcomesConfiguration$ = this.navigationState$.pipe(
        combineLatestWith(this._authorizationService.organizationId$),
        switchMap(([{ registryId, subsetId }, organizationId]) =>
            this._dataAccessApiService
                .getOutcomesConfiguration(registryId, subsetId, organizationId)
                .pipe(startWith(null)),
        ),
        shareReplay(1),
        filter((configuration): configuration is OutcomesConfiguration => configuration != null),
    );

    readonly registryPermissions$ = this.currentRegistry$.pipe(
        switchMap(registryId => this._authorizationService.getRegistryInfo(registryId, true)),
        filter((registryInfo): registryInfo is AuthRegistryInfo => registryInfo != null),
        map(
            registryInfo =>
                registryInfo.permissions.find(
                    registryProductPersmissions =>
                        registryProductPersmissions.applicationInstance ===
                        this._authorizationService.currentAppInstance,
                )?.permissions,
        ),
    );

    readonly benchmarkApiParams$: Observable<BenchmarkParams> =
        this._benchmarkingService.selectedBenchmark$.pipe(
            map(selectedBenchmark => {
                const params: BenchmarkParams = {};
                if (selectedBenchmark.type === "regional") {
                    params.regions = selectedBenchmark.regions[0];
                } else if (selectedBenchmark.type === "connect") {
                    params.regions = selectedBenchmark.regions[0];
                    params.connectBenchmarkProviders = selectedBenchmark.connectProviders;
                } else if (selectedBenchmark.type === "providerType") {
                    params.regions = selectedBenchmark.regions[0];
                    params.providerType = selectedBenchmark.providerType;
                }
                return params;
            }),
        );

    constructor() {
        this.yearFilter$ = this._initializeYearFilter$(
            this.navigationState$,
            this._authorizationService.organizationId$,
        );
        this._startTracking();
    }

    setCurrentRegistry(id: string): void {
        this._authorizationService.availableRegistries$.pipe(first()).subscribe(registryIds => {
            if (registryIds.includes(id)) {
                this._currentRegistry$.next(id);
            }
        });
    }

    setCurrentSubset(subsetId: string): void {
        combineLatest([this.currentRegistry$, this.registryStructureLookup$])
            .pipe(first())
            .subscribe(([registryId, lookup]) => {
                const stateIsCorrect = this._checkNavigationParams(lookup, registryId, subsetId);
                if (stateIsCorrect) {
                    this._currentSubset$.next(subsetId);
                }
            });
    }

    setCurrentPage(pageId: string): void {
        combineLatest([this.navigationState$, this.registryStructureLookup$])
            .pipe(first())
            .subscribe(([{ registryId, subsetId }, lookup]) => {
                const stateIsCorrect = this._checkNavigationParams(
                    lookup,
                    registryId,
                    subsetId,
                    pageId,
                );
                if (stateIsCorrect) {
                    this._currentPage$.next(pageId);
                }
            });
    }

    addExpandedOutcomesSection(
        registryId: string,
        subsetId: string,
        pageId: string,
        sectionId: string,
    ): void {
        const fullSectionPath = `${registryId}_${subsetId}_${pageId}_${sectionId}`;
        this._expandedOutcomesSections.push(fullSectionPath);
    }

    removeExpandedOutcomesSection(
        registryId: string,
        subsetId: string,
        pageId: string,
        sectionId: string,
    ): void {
        const fullSectionPath = `${registryId}_${subsetId}_${pageId}_${sectionId}`;
        this._expandedOutcomesSections = this._expandedOutcomesSections.filter(
            section => section !== fullSectionPath,
        );
    }

    isSectionExpanded(
        registryId: string,
        subsetId: string,
        pageId: string,
        sectionId: string,
    ): boolean {
        const fullSectionPath = `${registryId}_${subsetId}_${pageId}_${sectionId}`;
        return this._expandedOutcomesSections.includes(fullSectionPath);
    }

    mergeConfigurationInOutcomes(
        outcome: IOutcomeUniversal,
        subsetConfiguration: ISubsetConfiguration,
        medicalParameter?: MedicalItem,
        outcomeConfiguration?: OutcomeConfiguration,
    ): OutcomeWithConfigurationUniversal {
        const calculationType =
            (outcome.third ? outcome.third?.data?.type : undefined) ??
            outcome.second.data?.type ??
            outcome.first.data?.type ??
            "Average";

        let unitFromMedicalParameter: Unit | undefined;
        if (medicalParameter && "unit" in medicalParameter) {
            unitFromMedicalParameter = medicalParameter.unit;
        }

        let unit = EMPTY_UNIT;
        if (
            unitFromMedicalParameter &&
            unitFromMedicalParameter !== "percentage" &&
            unitFromMedicalParameter !== "euros"
        ) {
            unit = `Common.Units.${unitFromMedicalParameter}`;
        }
        if (unitFromMedicalParameter === "percentage" && calculationType === "Average") {
            unit = "%";
        }
        if (calculationType === "Count") {
            unit = `Common.AnalysisUnit.${subsetConfiguration.analysisUnit}`;
        }

        const outcomeWithConfiguration: OutcomeWithConfigurationUniversal = {
            ...outcome,
            calculationType,
            formatter: this._getFormatter(calculationType, unitFromMedicalParameter),
            unit,
            ...outcomeConfiguration,
        };
        return outcomeWithConfiguration;
    }

    handlePatientInfoEvent(
        event: ComparisonPopupInfoClickEvent,
        analysisUnit: AnalysisUnit,
        selectedOrganizationName: string,
        patientsTrend$: Observable<ITrendMoment[]>,
        patientsCount$: Observable<IPatientCount>,
        trendDialog: UiTrendDialog<ITrendMoment[]>,
        patientsListDialog: PatientsListDialog<PatientList[]>,
    ): void {
        if (event.type === "trend") {
            this.showTrendDialog(
                analysisUnit,
                selectedOrganizationName,
                patientsTrend$,
                trendDialog,
            );
        } else if (event.type === "list") {
            this.showPatientsListDialog(analysisUnit, patientsCount$, patientsListDialog);
        }
    }

    showTrendDialog(
        analysisUnit: string,
        selectedOrganizationName: string,
        data$: Observable<ITrendMoment[]>,
        trendDialog: UiTrendDialog<ITrendMoment[]>,
    ): void {
        const analysisUnitTranslation = this._lgTranslateService
            .translate("Common.AnalysisUnit." + analysisUnit)
            .toLowerCase();
        trendDialog.show({
            data$,
            getGroupValues: (item: ITrendMoment): Array<number | null> => [item.count],
            getColumnNames: (item: ITrendMoment): string => item.year.toString(),
            title: this._lgTranslateService.translate("APP.TrendDialog.Title", {
                unit: analysisUnitTranslation,
            }),
            yAxisLabel: this._lgTranslateService.translate("APP.TrendDialog.YAxisTitle", {
                unit: analysisUnitTranslation,
            }),
            groupLabel: selectedOrganizationName,
            infoText: this._lgTranslateService.translate("APP.TrendDialog.Explanation", {
                unit: analysisUnitTranslation,
            }),
            ownProviderColor: this._benchmarkingService.colors.ownProvider,
        });
    }

    showPatientsListDialog(
        analysisUnit: string,
        totalRecords$: Observable<IPatientCount>,
        patientsListDialog: PatientsListDialog<PatientList[]>,
    ): void {
        const analysisUnitTranslation = this._lgTranslateService
            .translate("Common.AnalysisUnit." + analysisUnit)
            .toLowerCase();

        patientsListDialog.show({
            dataColumns$: this.subsetConfiguration$.pipe(map(config => config.patientListItems)),
            title: this._lgTranslateService.translate("APP.PatientListDialog.Title", {
                unit: upperFirst(analysisUnitTranslation),
            }),
            registryId$: this.currentRegistry$,
            totalRecords$,
        });
    }

    getPatientsCount$(
        navigationState$: Observable<NavigationState>,
        organizationId$: Observable<number>,
        activeFilters$: Observable<ActiveFilters>,
        selectedFilterSplits$?: Observable<FilterSplits[]>,
        selectedDistributionLevel1$?: Observable<string | undefined>,
        selectedDistributionLevel2$?: Observable<string | undefined>,
        distributionLevel1Parameter$?: Observable<string | undefined>,
        distributionLevel2Parameter$?: Observable<string | undefined>,
    ): Observable<IPatientCount> {
        return combineLatest([
            navigationState$,
            organizationId$,
            activeFilters$,
            selectedFilterSplits$ ?? of([]),
            selectedDistributionLevel1$ ?? of(undefined),
            selectedDistributionLevel2$ ?? of(undefined),
            distributionLevel1Parameter$ ?? of(undefined),
            distributionLevel2Parameter$ ?? of(undefined),
        ]).pipe(
            switchMap(
                ([
                    { registryId, subsetId },
                    organizationId,
                    { timeRange, filters },
                    selectedFilterSplits,
                    selectedDistributionLevel1,
                    selectedDistributionLevel2,
                    distributionLevel1Parameter,
                    distributionLevel2Parameter,
                ]) =>
                    this._dataAccessApiService.getPatientsCount(
                        registryId,
                        subsetId,
                        organizationId,
                        timeRange,
                        this.mergeFilters(
                            filters,
                            selectedFilterSplits,
                            { id: distributionLevel1Parameter, value: selectedDistributionLevel1 },
                            { id: distributionLevel2Parameter, value: selectedDistributionLevel2 },
                        ),
                    ),
            ),
            shareReplay({ bufferSize: 1, refCount: true }),
        );
    }

    getPatientTrend$(
        navigationState$: Observable<NavigationState>,
        organizationId$: Observable<number>,
        activeFilters$: Observable<ActiveFilters>,
        selectedFilterSplits$?: Observable<FilterSplits[]>,
        selectedDistributionLevel1$?: Observable<string | undefined>,
        selectedDistributionLevel2$?: Observable<string | undefined>,
        distributionLevel1Parameter$?: Observable<string | undefined>,
        distributionLevel2Parameter$?: Observable<string | undefined>,
    ): Observable<ITrendMoment[]> {
        return combineLatest([
            navigationState$,
            organizationId$,
            activeFilters$,
            selectedFilterSplits$ ?? of([]),
            selectedDistributionLevel1$ ?? of(undefined),
            selectedDistributionLevel2$ ?? of(undefined),
            distributionLevel1Parameter$ ?? of(undefined),
            distributionLevel2Parameter$ ?? of(undefined),
        ]).pipe(
            switchMap(
                ([
                    { registryId, subsetId },
                    organizationId,
                    { filters },
                    selectedFilterSplits,
                    selectedDistributionLevel1,
                    selectedDistributionLevel2,
                    distributionLevel1Parameter,
                    distributionLevel2Parameter,
                ]) =>
                    this._dataAccessApiService.getPatientTrend(
                        registryId,
                        subsetId,
                        "year",
                        organizationId,
                        this.mergeFilters(
                            filters,
                            selectedFilterSplits,
                            { id: distributionLevel1Parameter, value: selectedDistributionLevel1 },
                            { id: distributionLevel2Parameter, value: selectedDistributionLevel2 },
                        ),
                    ),
            ),
            shareReplay(1),
        );
    }

    mergeFilters(
        filters: FilterParam[] | undefined,
        selectedFilterSplits: FilterSplits[],
        selectedDistributionLevel1?: { id: string | undefined; value: string | undefined },
        selectedDistributionLevel2?: { id: string | undefined; value: string | undefined },
    ): FilterParams | undefined {
        const filtersWithSplit = filters ? [...filters] : [];
        selectedFilterSplits?.forEach(item => {
            if (item.filterSplitValue) {
                filtersWithSplit?.push({
                    id: item.filterSplitId,
                    type: "category",
                    values: [item.filterSplitValue],
                });
            }
        });
        if (
            selectedDistributionLevel1?.id &&
            selectedDistributionLevel1?.id !== EMPTY_PARAMETER &&
            selectedDistributionLevel1?.value &&
            selectedDistributionLevel1?.value !== EMPTY_PARAMETER
        ) {
            filtersWithSplit?.push({
                id: selectedDistributionLevel1.id,
                type: "category",
                values: [selectedDistributionLevel1.value],
            });
        }
        if (
            selectedDistributionLevel2?.id &&
            selectedDistributionLevel2?.id !== EMPTY_PARAMETER &&
            selectedDistributionLevel2?.value &&
            selectedDistributionLevel2?.value !== EMPTY_PARAMETER
        ) {
            filtersWithSplit?.push({
                id: selectedDistributionLevel2.id,
                type: "category",
                values: [selectedDistributionLevel2.value],
            });
        }
        return filtersWithSplit.length ? filtersWithSplit : undefined;
    }

    private _startTracking(): void {
        this.currentRegistry$
            .pipe(
                switchMap(registry =>
                    combineLatest([of(registry), this._filterNavigationEndEvent()]).pipe(take(1)),
                ),
            )
            .subscribe(([registry]) => {
                if (registry) {
                    this._tracer.setRegistry(registry);
                    this._tracer.setTenant(TENANT_LOOKUP[registry]);
                }
            });

        this.currentSubset$
            .pipe(
                switchMap(subset =>
                    combineLatest([of(subset), this._filterNavigationEndEvent()]).pipe(take(1)),
                ),
            )
            .subscribe(([subset]) => {
                if (subset) {
                    this._tracer.setSubset(subset);
                }
            });

        this.currentPage$
            .pipe(
                switchMap(page =>
                    combineLatest([of(page), this._filterNavigationEndEvent()]).pipe(take(1)),
                ),
            )
            .subscribe(([page]) => {
                if (page) {
                    this._tracer.setPage(page);
                }
            });
    }

    private _filterNavigationEndEvent = (): Observable<NavigationEnd> => {
        return this._router.events.pipe(
            filter((e): e is NavigationEnd => e instanceof NavigationEnd),
        );
    };

    private _initializeYearFilter$(
        navigationState$: Observable<NavigationState>,
        organizationId$: Observable<number>,
    ): Observable<TimeRangeFilterRange> {
        return combineLatest([navigationState$, organizationId$]).pipe(
            switchMap(([{ registryId, subsetId }, organizationId]) =>
                this._dataAccessApiService
                    .getYearsFilterCount(registryId, subsetId, organizationId)
                    .pipe(
                        map(data => {
                            const currentMinDateArray = data?.minDate?.split("-");
                            const currentMaxDateArray = data?.maxDate?.split("-");
                            if (
                                currentMinDateArray &&
                                currentMaxDateArray &&
                                currentMinDateArray.length >= 2 &&
                                currentMaxDateArray.length >= 2
                            ) {
                                const minYear = currentMinDateArray[0];
                                const maxYear = currentMaxDateArray[0];
                                const minMonth = currentMinDateArray[1];
                                const maxMonth = currentMaxDateArray[1];
                                if (
                                    !Number.isNaN(minYear) &&
                                    !Number.isNaN(maxYear) &&
                                    !Number.isNaN(minMonth) &&
                                    !Number.isNaN(maxMonth)
                                )
                                    return {
                                        minYear: Number(minYear),
                                        maxYear: Number(maxYear),
                                        minMonth: Number(minMonth),
                                        maxMonth: Number(maxMonth),
                                    };
                            }
                            const currentYear = new Date().getFullYear();
                            return {
                                minYear: 2014,
                                maxYear: currentYear,
                                minMonth: 1,
                                maxMonth: 12,
                            };
                        }),
                    ),
            ),
        );
    }

    private _getRegistryStructureLookup(structure: IRegistryStructure): IRegistryStructureLookup {
        return structure.subsets.reduce(
            (lookup, subset) => {
                lookup[subset.id] = subset.pages.reduce(
                    (pagesLookup, page) => {
                        pagesLookup[page.id] = page.sections.map(section => section.id);
                        return pagesLookup;
                    },
                    <{ [pageId: string]: string[] }>{},
                );
                return lookup;
            },
            <IRegistryStructureLookup>{},
        );
    }

    private _checkNavigationParams(
        lookup: IRegistryStructureLookup,
        registryId: string,
        subsetId: string,
        pageId?: string,
    ): boolean {
        let navigationParamsCorrect = false;
        const subset = lookup[subsetId];
        if (subset) {
            if (pageId == null) {
                navigationParamsCorrect = true;
            } else if (subset[pageId]) {
                navigationParamsCorrect = true;
            } else {
                pageId = Object.keys(subset)[0];
                navigationParamsCorrect = false;
            }
        } else {
            subsetId = Object.keys(lookup)[0];
            pageId = Object.keys(lookup[subsetId])[0];
            navigationParamsCorrect = false;
        }

        if (!navigationParamsCorrect) {
            this._router.navigate([registryId, "subsets", subsetId, pageId], { replaceUrl: true });
        }

        return navigationParamsCorrect;
    }

    private _getFormatter(calculationType: CalculationType, unit: Unit | undefined): string {
        if (calculationType === "Percentage" || calculationType === "DistributionPercentage") {
            return "percent";
        } else if (calculationType === "Average") {
            if (unit === "euros") {
                return "money";
            } else {
                return "float";
            }
        } else if (calculationType === "Median") {
            return "int";
        } else {
            return "float";
        }
    }
}
