export interface IBenchmarkConfiguration {
    regions: string[];
    defaultRegion?: string;
    providerTypes: string[];
    defaultProviderType?: string;
    connect: IConnectProvider[];
    benchmarkDimension?: IBenchmarkDimension;
}

export interface IBenchmarkDimension {
    dimensions: string[];
    outcomeCode: string;
}

export interface IConnectProvider {
    id: number;
    name: string;
}

export type Benchmark =
    | INationalBenchmark
    | IRegionalBenchmark
    | IInternationalBenchmark
    | IConnectBenchmark
    | IProviderTypeBenchmark;

export interface IPatientsCount {
    numerator: number;
    denominator: number;
    connectBenchmarkNumerator: number;
    connectBenchmarkDenominator: number;
    nationalBenchmarkNumerator: number;
    nationalBenchmarkDenominator: number;
    providerTypeBenchmarkNumerator: number;
    providerTypeBenchmarkDenominator: number;
}

export interface IPatientsCountMultiData {
    multiData: { numerator: number; denominator: number }[];
}

/**
 * @deprecated
 * Should be replaced with regional
 */
export interface INationalBenchmark {
    /**
     * @deprecated
     * Should be replaced with regional
     */
    type: "national";
}

export interface IRegionalBenchmark {
    type: "regional";
    regions: Regions;
}

export interface IInternationalBenchmark {
    type: "international";
}

export interface IConnectBenchmark {
    type: "connect";
    connectProviders: number[];
    regions: Regions;
}

export interface IProviderTypeBenchmark {
    type: "providerType";
    providerType: string;
    regions: Regions;
}

export type Regions = [string, ...string[]];
export type Provider =
    | "ownProvider"
    | "internationalBenchmark"
    | "nationalBenchmark"
    | "regionalBenchmark"
    | "providerTypeBenchmark"
    | "connectBenchmark"
    | "sharedOrganization";
export type ProviderForUrl = "own" | "national" | "connect";
export const providersForUrl: { provider: Provider; urlParam: string }[] = [
    { provider: "ownProvider", urlParam: "own" },
    { provider: "nationalBenchmark", urlParam: "national" },
    { provider: "connectBenchmark", urlParam: "connect" },
    { provider: "providerTypeBenchmark", urlParam: "providerType" },
];

export type ICurrentSourceType = "first" | "second" | "third";

export type IComparisonType = "basic" | "multiData";
export type IMultiDataSelection = { provider: Provider; dimensions: string[] };

export const PROVIDERS: Provider[] = [
    "ownProvider",
    "connectBenchmark",
    "providerTypeBenchmark",
    "nationalBenchmark",
    "regionalBenchmark",
    "internationalBenchmark",
];

export type BenchmarkType = "national" | "regional" | "international" | "connect" | "providerType";

export const INITIAL_BENCHMARK: Benchmark = {
    type: "regional",
    regions: ["nl"],
};

export interface IRegistryConfiguration {
    moments: { [key: string]: string };
    benchmarkTypes: BenchmarkType[];
    providersCountThreshold?: number;
}
