import { ComponentType } from "@angular/cdk/portal";
import { Component, Injectable } from "@angular/core";

import {
    FilterRendererComponentBase,
    IFilterDefinition,
    IFilterRenderer,
    IFilterRendererFactory,
    LgFilterSet,
} from "@logex/framework/lg-filterset";

import { Range, BaseRangeRenderer } from "@codman/shared/util-filters";

import { IHistogramFilterDefinition, HistogramFilterStorage } from "./histogram-filter.types";

export class HistogramFilterRenderer
    extends BaseRangeRenderer<IHistogramFilterDefinition>
    implements IFilterRenderer
{
    getFilterLineComponent(): ComponentType<
        FilterRendererComponentBase<IHistogramFilterDefinition, HistogramFilterRenderer>
    > {
        return HistogramFilterRendererLineComponent;
    }

    getPopupComponent(): ComponentType<
        FilterRendererComponentBase<IHistogramFilterDefinition, HistogramFilterRenderer>
    > {
        return HistogramFilterRendererLineComponent;
    }
}

// Factory ---------------------------------------------------------------------------------------------------------
@Injectable()
export class HistogramFilterRendererFactory implements IFilterRendererFactory {
    readonly name: string = "histogram";

    create(
        definition: IHistogramFilterDefinition,
        filters: HistogramFilterStorage,
        filterDefitions: IFilterDefinition[],
        filterSet: LgFilterSet,
    ): IFilterRenderer {
        return new HistogramFilterRenderer(definition, filters, filterDefitions, filterSet);
    }
}

// Line template  --------------------------------------------------------------------------------------------------
@Component({
    selector: "histogram-filter-renderer-line",
    template: `<codman-histogram-filter
        [min]="_definition.min"
        [max]="_definition.max"
        [range]="_filters[_definition.storage || '']"
        [source]="_definition.source"
        (didChange)="_emitChange($event)"
    ></codman-histogram-filter>`,
    standalone: false,
})
export class HistogramFilterRendererLineComponent extends FilterRendererComponentBase<
    IHistogramFilterDefinition,
    HistogramFilterRenderer
> {
    _emitChange(range: Range): void {
        this._renderer.update(range.min, range.max);
        this._triggerChange();
    }
}
