import { ComponentType } from "@angular/cdk/portal";
import { ChangeDetectorRef, Component, Injectable, OnInit, inject } from "@angular/core";

import {
    FilterRendererComponentBase,
    IFilterDefinition,
    IFilterRenderer,
    IFilterRendererFactory,
    LgFilterSet,
} from "@logex/framework/lg-filterset";
import { IFilterExportDefinition } from "@logex/framework/lg-exports";

import { BaseTimeRangeFilterStorage, TimeRange } from "@codman/shared/util-filters";

import { DataAccessFiltersService } from "@codman/exploration/data-access-base";

import { ITimeRangeFilterDefinition, TimeRangeFilterStorage } from "./time-range-filter.types";
import { BehaviorSubject, Observable } from "rxjs";
import { FormStyle, TranslationWidth, getLocaleMonthNames } from "@angular/common";

import {
    loadTimeRangeDefaultNumberOfYears,
    loadTimeRangeFromCache,
    loadTimeRangeRollingYear,
} from "../time-range-functions";
import { timeRangeUrlParam, trendIntervalUrlParam } from "@codman/shared/types";

export class TimeRangeFilterRenderer implements IFilterRenderer {
    protected _minYear: number;
    protected _maxYear: number;
    protected _minMonth: number;
    protected _maxMonth: number;
    protected _storage: TimeRange | null = null;

    private _previewName = new BehaviorSubject<string>("");
    private _previewName$ = this._previewName.asObservable();

    constructor(
        protected _definition: ITimeRangeFilterDefinition,
        protected _filters: BaseTimeRangeFilterStorage,
        protected _filterDefinitions: IFilterDefinition[],
        protected _filterSet: LgFilterSet,
    ) {
        this._minYear = this._definition.minYear || 0;
        this._maxYear = this._definition.maxYear || 0;
        this._minMonth = this._definition.minMonth || 0;
        this._maxMonth = this._definition.maxMonth || 0;
    }

    getFilterLineComponent(): ComponentType<
        FilterRendererComponentBase<ITimeRangeFilterDefinition, TimeRangeFilterRenderer>
    > {
        return TimeRangeFilterRendererLineComponent;
    }

    getPopupComponent(): ComponentType<
        FilterRendererComponentBase<ITimeRangeFilterDefinition, TimeRangeFilterRenderer>
    > {
        return TimeRangeFilterRendererLineComponent;
    }

    createStorage(): void {
        if (this._storage == null) {
            this._createStorage();
        }
    }

    update(
        selectedMinYear: number | null,
        selectedMaxYear: number | null,
        selectedMinMonth: number | null,
        selectedMaxMonth: number | null,
    ): boolean {
        if (!this._storage) {
            return false;
        }

        const { minYear, maxYear, minMonth, maxMonth } = this._storage;

        this._storage.minYear = selectedMinYear;
        this._storage.maxYear = selectedMaxYear;
        this._storage.minMonth = selectedMinMonth;
        this._storage.maxMonth = selectedMaxMonth;

        if (this.previewVisible()) {
            const name = this._definition.nameLC
                ? this._filterSet.lgTranslate.translate(this._definition.nameLC)
                : this._definition.name;
            this._previewName.next(`${name} (${this.serialize()})`);
        }

        return (
            minYear !== this._storage.minYear ||
            maxYear !== this._storage.maxYear ||
            minMonth !== this._storage.minMonth ||
            maxMonth !== this._storage.maxMonth
        );
    }

    clear(): boolean {
        const result = this.update(null, null, null, null);
        if (result) {
            localStorage.removeItem(trendIntervalUrlParam);
            localStorage.removeItem(timeRangeUrlParam);
        }
        return result;
    }

    active(): boolean {
        return (
            this._storage?.minYear != null ||
            this._storage?.maxYear != null ||
            this._storage?.minMonth != null ||
            this._storage?.maxMonth != null
        );
    }

    previewVisible(): boolean {
        return this.active();
    }

    getExportDefinition(): IFilterExportDefinition {
        return {
            name: this._definition.name || "",
            activeFn: () => this.active(),
            exportFn: () => [this._serializeValue()],
        };
    }

    getPreviewName(): Observable<string> {
        return this._previewName$;
    }

    serialize(): string {
        if (!this.active()) return "";
        return this._serializeValue();
    }

    deserialize(state: string): boolean {
        if (!this._storage) {
            return false;
        }
        const [selectedMin, selectedMax] = state.split("~");
        const selectedMinItems = selectedMin.split("-");
        const selectedMaxItems = selectedMax.split("-");
        return this.update(
            Number(selectedMinItems[0]),
            Number(selectedMaxItems[0]),
            Number(selectedMinItems[1]),
            Number(selectedMaxItems[1]),
        );
    }

    private _serializeValue(): string {
        if (!this._storage) {
            return "";
        }

        const monthNames = [
            ...getLocaleMonthNames(
                this._filterSet.lgTranslate.getLanguage(),
                FormStyle.Format,
                TranslationWidth.Abbreviated,
            ),
        ];

        if (
            this._storage?.minYear &&
            this._storage?.maxYear &&
            this._storage?.minMonth &&
            this._storage?.maxMonth &&
            this._definition.nameLC
        ) {
            const minDate = `${monthNames[this._storage.minMonth - 1]} ${this._storage.minYear}`;
            const maxDate = `${monthNames[this._storage.maxMonth - 1]} ${this._storage.maxYear}`;
            return minDate === maxDate ? `${minDate}` : `${minDate} - ${maxDate}`;
        }

        return "";
    }

    private _createStorage(): void {
        const key = this._definition.storage || "";

        this._filters[key] = {
            minYear: null,
            maxYear: null,
            minMonth: null,
            maxMonth: null,
        };

        this._storage = this._filters[key];
    }
}

// Factory ---------------------------------------------------------------------------------------------------------
@Injectable()
export class TimeRangeFilterRendererFactory implements IFilterRendererFactory {
    readonly name: string = "timeRange";

    create(
        definition: ITimeRangeFilterDefinition,
        filters: TimeRangeFilterStorage,
        filterDefinitions: IFilterDefinition[],
        filterSet: LgFilterSet,
    ): IFilterRenderer {
        return new TimeRangeFilterRenderer(definition, filters, filterDefinitions, filterSet);
    }
}

// Line template  --------------------------------------------------------------------------------------------------
@Component({
    selector: "time-range-filter-renderer-line",
    template: `<codman-time-range-filter
        [minYear]="_definition.minYear"
        [maxYear]="_definition.maxYear"
        [minMonth]="_definition.minMonth"
        [maxMonth]="_definition.maxMonth"
        [registry]="_definition.registry"
        [subset]="_definition.subset"
        [range]="_filters[_definition.storage || '']"
        (didChange)="_emitChange($event)"
    ></codman-time-range-filter> `,
    standalone: false,
})
export class TimeRangeFilterRendererLineComponent
    extends FilterRendererComponentBase<ITimeRangeFilterDefinition, TimeRangeFilterRenderer>
    implements OnInit
{
    private _cdRef = inject(ChangeDetectorRef);
    private _dataAccessFiltersService = inject(DataAccessFiltersService);

    ngOnInit(): void {
        this._dataAccessFiltersService.trendInterval$.subscribe(trendInterval => {
            const timeRangeRollingYear = loadTimeRangeRollingYear();
            const timeRangeFromCache = loadTimeRangeFromCache(
                this._definition.registry,
                this._definition.subset,
            );
            const timeRangeDefaultNumberOfYears = loadTimeRangeDefaultNumberOfYears(
                this._definition.defaultNumberOfYearsSelected,
            );
            if (trendInterval === "rollingYear" && timeRangeRollingYear) {
                this._renderer.update(
                    timeRangeRollingYear.minYear,
                    timeRangeRollingYear.maxYear,
                    timeRangeRollingYear.minMonth,
                    timeRangeRollingYear.maxMonth,
                );
            } else if (timeRangeFromCache) {
                this._renderer.update(
                    timeRangeFromCache.minYear,
                    timeRangeFromCache.maxYear,
                    timeRangeFromCache.minMonth,
                    timeRangeFromCache.maxMonth,
                );
            } else if (
                this._definition.defaultNumberOfYearsSelected &&
                timeRangeDefaultNumberOfYears
            ) {
                this._renderer.update(
                    timeRangeDefaultNumberOfYears.minYear,
                    timeRangeDefaultNumberOfYears.maxYear,
                    timeRangeDefaultNumberOfYears.minMonth,
                    timeRangeDefaultNumberOfYears.maxMonth,
                );
            } else {
                this._renderer.update(null, null, null, null);
            }
            this._cdRef.markForCheck();
        });
    }

    _emitChange(range: TimeRange): void {
        localStorage.removeItem(trendIntervalUrlParam);
        this._renderer.update(range.minYear, range.maxYear, range.minMonth, range.maxMonth);
        this._triggerChange();
    }
}
