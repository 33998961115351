// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .lg-tooltip--d3--wide {
  max-width: fit-content;
}

::ng-deep .lg-tooltip--d3--extra-wide {
  max-width: fit-content;
}

::ng-deep .lg-chart__tooltip__table__item__name_column {
  white-space: nowrap;
}`, "",{"version":3,"sources":["webpack://./apps/exploration/src/app/app.component.scss"],"names":[],"mappings":"AAAA;EACI,sBAAA;AACJ;;AAEA;EACI,sBAAA;AACJ;;AAEA;EACI,mBAAA;AACJ","sourcesContent":["::ng-deep .lg-tooltip--d3--wide {\n    max-width: fit-content;\n}\n\n::ng-deep .lg-tooltip--d3--extra-wide {\n    max-width: fit-content;\n}\n\n::ng-deep .lg-chart__tooltip__table__item__name_column {\n    white-space: nowrap;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
