import { Observable, of } from "rxjs";
import {
    Benchmark,
    IBenchmarkConfiguration,
    IConnectProvider,
    Regions,
} from "./feature-benchmarking.types";

export class FeatureBenchmarkingMockService {
    readonly colors: { [key: string]: string } = {
        ownProvider: "#00a3ad",
        national: "#b0b0b0",
        regional: "#b0b0b0",
        international: "#b0b0b0",
        connect: "#c79dff",
        sharedOrganization: "#8c00e0",
        providerType: "#6a8dff",
    };

    readonly availableConnectProviders$: Observable<IConnectProvider[]> = of([
        { id: 10003, name: "demo" },
    ]);

    readonly selectedBenchmark$: Observable<Benchmark> = of({ type: "regional", regions: ["nl"] });
    readonly selectedConnectProviders$: Observable<number[] | undefined> = of([10003]);
    readonly selectedProviderType$: Observable<string | undefined> = of("providerType");
    readonly selectedRegions$: Observable<Regions> = of(["nl"]);

    readonly selectedBenchmarkColor$: Observable<string> = of("fff");
    readonly selectedBenchmarkLabel$: Observable<string> = of("NL benchmark");

    initialize(configuration$: Observable<IBenchmarkConfiguration>): void {
        //
    }

    updateSelectedBenchmark(selectedBenchmark: Benchmark): void {
        //
    }

    resetSelectedBenchmark(): void {
        //
    }

    getIndicatorsColors(): { [key: string]: string } {
        return {
            ownProvider: this.colors["ownProvider"],
            benchmark: this.colors["national"],
            connectBenchmark: this.colors["connect"],
            providerTypeBenchmark: this.colors["providerType"],
        };
    }

    getExplorationColors(): { [key: string]: string } {
        return {
            ownProvider: this.colors["ownProvider"],
            nationalBenchmark: this.colors["national"],
            internationalBenchmark: this.colors["international"],
            connectBenchmark: this.colors["connect"],
            sharedOrganization: this.colors["sharedOrganization"],
            providerTypeBenchmark: this.colors["providerType"],
        };
    }

    addCurrentProvidersToUrl(): void {
        //
    }
}
