import { Injectable, inject } from "@angular/core";
import { HttpResponse } from "@angular/common/http";

import { forkJoin, Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { lowerFirst } from "lodash";
import _ from "lodash";

import { ServerGatewayBase } from "@logex/framework/lg-application";

import { SharedConfigService } from "@codman/shared/util-logex-framework-setup";
import { TimeRange } from "@codman/shared/util-filters";
import { IStatus } from "@codman/shared/types";
import { TrendInterval } from "@codman/shared/assets";
import {
    BenchmarkType,
    defaultProviders,
    IRegistryConfiguration,
    Provider,
    IComparisonType,
    IMultiDataSelection,
    providersForUrl,
    IPatientsCountMultiData,
} from "@codman/shared/feature-benchmarking";

import { EMPTY_PARAMETER } from "libs/exploration/util-translating/src/lib/outcome-label.pipe";

import {
    FilterCounts,
    IOutcome,
    IOutcomeTrend,
    IPatientCount,
    IRegistryStructure,
    ITrendMoment,
    CategoryQueryParams,
    SEPARATORS,
    TimeRangeFilterRange,
    FilterParams,
    RangeQueryParams,
    StringQueryParams,
    NonFilterParams,
    ORG_ID_HEADER,
    OutcomesConfiguration,
    OutcomeConfiguration,
    ISubsetConfiguration,
    AnalysisUnit,
    PatientList,
    ISection,
    IProvider,
    ReferenceOutcome,
    FilterComparison,
    NumberQueryParams,
    ISortDirection,
    IBenchmarkConfiguration,
    IEventAnalysis,
    BenchmarkParams,
    TimeRangeQueryParams,
    IOutcomeWithSource,
    IOutcomeUniversal,
    IOutcomeTrendUniversal,
    FilterComparisonUniversal,
    IFilterOption,
    IEventAnalysisUniversal,
    IEventAnalysisInterval,
    IEventAnalysisIntervalReferenceWithSource,
    IDistributionPercentageOutcomeLevel2,
    ISectionFilter,
    IDistribution,
    IDistributionPercentageOutcome,
    ISankeyUniversal,
    ISankey,
    SelectedFilterSplits,
    IDataExportSection,
    IDataExportSectionUniversal,
    IDataExportOutcomeUniversal,
    IDistributionOutcome,
    CalculationType,
    IDistributionPercentage,
    IDistributionAverage,
    IDistributionMedian,
    FilterParam,
    FilterSplit,
} from "./data-access-api.types";

@Injectable({
    providedIn: "root",
})
export class DataAccessApiService extends ServerGatewayBase {
    protected _configService = inject(SharedConfigService);

    constructor() {
        super();
        const apiUrl = this._configService.getConfiguration()?.apiUrl;
        if (apiUrl != null) {
            this._setBaseUrl(apiUrl);
        } else {
            console.error("Cannot set application base API url based on configuration.");
        }
    }

    /**
     * Configuration
     */
    getRegistryStructure(
        registryId: string,
        organizationId: number,
    ): Observable<IRegistryStructure> {
        return this._get<IRegistryStructure>(`${registryId}/structure`, {
            headers: {
                [ORG_ID_HEADER]: organizationId.toString(),
            },
        });
    }

    getRegistryConfiguration(
        registryId: string,
        organizationId: number,
    ): Observable<IRegistryConfiguration> {
        return this._get<IRegistryConfiguration>(`${registryId}/registryconfiguration`, {
            headers: {
                [ORG_ID_HEADER]: organizationId.toString(),
            },
        }).pipe(
            // Backend sends First letter upercase and it is easier for us to work with lowecase ids
            map(result => {
                const benchmarkTypes = result.benchmarkTypes.map(benchmarkType =>
                    lowerFirst(benchmarkType),
                ) as BenchmarkType[];
                return { ...result, benchmarkTypes };
            }),
        );
    }

    getBenchmarkConfiguration(
        registryId: string,
        subsetId: string,
        organizationId: number,
    ): Observable<IBenchmarkConfiguration> {
        return this._get<IBenchmarkConfiguration>(
            `${registryId}/${subsetId}/benchmarkConfiguration`,
            {
                headers: {
                    [ORG_ID_HEADER]: organizationId.toString(),
                },
            },
        );
    }

    getSubsetConfiguration(
        registryId: string,
        subsetId: string,
        organizationId: number,
    ): Observable<ISubsetConfiguration> {
        // backend returning analysisLevel which is mapped for clearer naming in FE
        return this._get<ISubsetConfiguration & { analysisLevel: AnalysisUnit }>(
            `${registryId}/${subsetId}/subsetconfiguration`,
            {
                headers: {
                    [ORG_ID_HEADER]: organizationId.toString(),
                },
            },
        ).pipe(map(result => ({ ...result, analysisUnit: result.analysisLevel })));
    }

    getOutcomeConfiguration(
        registryId: string,
        subsetId: string,
        outcomeId: string,
        organizationId: number,
    ): Observable<OutcomeConfiguration> {
        return this._get<OutcomeConfiguration>(
            `${registryId}/${subsetId}/${outcomeId}/outcomeConfiguration`,
            {
                headers: {
                    [ORG_ID_HEADER]: organizationId.toString(),
                },
            },
        );
    }

    getPageOutcomes(
        registryId: string,
        subsetId: string,
        pageId: string,
        organizationId: number,
    ): Observable<ISection[]> {
        return this._get<ISection[]>(`${registryId}/${subsetId}/${pageId}/pageoutcomes`, {
            headers: {
                [ORG_ID_HEADER]: organizationId.toString(),
            },
        });
    }

    getOutcomesConfiguration(
        registryId: string,
        subsetId: string,
        organizationId: number,
    ): Observable<OutcomesConfiguration> {
        return this._get<OutcomesConfiguration>(`${registryId}/${subsetId}/outcomesConfiguration`, {
            headers: {
                [ORG_ID_HEADER]: organizationId.toString(),
            },
        });
    }

    /**
     * @deprecated use getBenchmarkConfiguration instead
     */
    getSharedProviders(registryId: string, organizationId: number): Observable<IProvider[]> {
        return this._get<IProvider[]>(
            `${registryId}/sharedproviders?providerId=${organizationId}`,
            {
                headers: {
                    [ORG_ID_HEADER]: organizationId.toString(),
                },
            },
        );
    }

    /**
     * Filters
     */
    getFilterCounts(
        registryId: string,
        subsetId: string,
        outcomeId: string | null,
        organizationId: number,
        selectedMultiDataProvider: Provider,
        comparisonType: IComparisonType,
        selectedConnectProviders: number[] | undefined,
        timeRange?: TimeRange,
        filters?: FilterParams,
    ): Observable<FilterCounts> {
        if (outcomeId == null) return of({} as FilterCounts);
        return this.getFiltered<FilterCounts>(
            `${registryId}/${subsetId}/${outcomeId}/filtercount`,
            organizationId,
            filters,
            {
                timeRange,
                multiDataSource:
                    comparisonType === "multiData"
                        ? (providersForUrl.find(
                              provider => provider.provider === selectedMultiDataProvider,
                          )?.urlParam ?? "own")
                        : undefined,
                connectBenchmarkProviders:
                    comparisonType === "multiData" &&
                    selectedConnectProviders &&
                    selectedMultiDataProvider === "connectBenchmark"
                        ? selectedConnectProviders
                        : undefined,
            },
        );
    }

    getYearsFilterCount(
        registryId: string,
        subsetId: string,
        organizationId: number,
    ): Observable<TimeRangeFilterRange> {
        return this._get<TimeRangeFilterRange>(`${registryId}/${subsetId}/yearsfilterrange`, {
            headers: {
                [ORG_ID_HEADER]: organizationId.toString(),
            },
        });
    }

    /**
     * Outcomes
     */
    getSingleOutcome(
        registryId: string,
        subsetId: string,
        outcomeId: string,
        organizationId: number,
        selectedProviders: Provider[],
        selectedProvidersMultiData: IMultiDataSelection,
        comparisonType: IComparisonType,
        benchmarkApiParams: BenchmarkParams,
        timeRange?: TimeRange,
        filters?: FilterParams,
    ): Observable<IOutcomeUniversal> {
        if (comparisonType === "multiData") {
            return this.getFiltered<IOutcome>(
                `${registryId}/${subsetId}/${outcomeId}/outcomeMultiData`,
                organizationId,
                filters,
                {
                    timeRange,
                    ...benchmarkApiParams,
                    multiDataSource:
                        providersForUrl.find(
                            provider => provider.provider === selectedProvidersMultiData.provider,
                        )?.urlParam ?? "own",
                    multiDataDimensions: selectedProvidersMultiData.dimensions.join(","),
                },
            ).pipe(
                map(outcome => {
                    return {
                        outcomeId: outcome.outcomeId,
                        medicalParameterId: outcome.medicalParameterId,
                        sharedProviders: outcome.sharedProviders,
                        distributionParameterL1: outcome.distributionParameterL1,
                        first: {
                            sourceType: selectedProvidersMultiData.provider,
                            data: outcome.multiData?.[0],
                        },
                        second: {
                            sourceType: selectedProvidersMultiData.provider,
                            data: outcome.multiData?.[1],
                        },
                        third: outcome.multiData?.[2]
                            ? {
                                  sourceType: selectedProvidersMultiData.provider,
                                  data: outcome.multiData?.[2],
                              }
                            : undefined,
                    };
                }),
            );
        } else
            return this.getFiltered<IOutcome>(
                `${registryId}/${subsetId}/${outcomeId}/outcome`,
                organizationId,
                filters,
                { timeRange, ...benchmarkApiParams },
            ).pipe(
                map(outcome => ({
                    outcomeId: outcome.outcomeId,
                    medicalParameterId: outcome.medicalParameterId,
                    sharedProviders: outcome.sharedProviders,
                    distributionParameterL1: outcome.distributionParameterL1,
                    first: this._getDataOfProvider(outcome, selectedProviders, 0),
                    second: this._getDataOfProvider(outcome, selectedProviders, 1),
                    third:
                        selectedProviders.length > 2
                            ? this._getDataOfProvider(outcome, selectedProviders, 2)
                            : undefined,
                })),
            );
    }

    getSectionOutcomes(
        registryId: string,
        subsetId: string,
        pageId: string,
        sectionId: string,
        organizationId: number,
        selectedProviders: Provider[],
        selectedProvidersMultiData: IMultiDataSelection,
        comparisonType: IComparisonType,
        benchmarkApiParams: BenchmarkParams,
        timeRange?: TimeRange,
        filters?: FilterParams,
    ): Observable<IOutcomeUniversal[]> {
        if (comparisonType === "multiData") {
            return this.getFiltered<IOutcome[]>(
                `${registryId}/${subsetId}/${pageId}/${sectionId}/outcomesMultiData`,
                organizationId,
                filters,
                {
                    timeRange,
                    ...benchmarkApiParams,
                    multiDataSource:
                        providersForUrl.find(
                            provider => provider.provider === selectedProvidersMultiData.provider,
                        )?.urlParam ?? "own",
                    multiDataDimensions: selectedProvidersMultiData.dimensions.join(","),
                },
            ).pipe(
                map(outcomes => {
                    let processedOutcomes: IOutcome[] = [];
                    outcomes.forEach(outcome => {
                        if (
                            outcome.distributionLevel === 2 &&
                            outcome[selectedProviders[0]]?.type === "DistributionPercentage"
                        ) {
                            let processedOutcomesToAddFirst = this._getProcessedOutcomes(
                                selectedProviders,
                                outcome,
                                (
                                    outcome[
                                        selectedProviders[0]
                                    ] as IDistributionPercentageOutcomeLevel2
                                ).distribution,
                                processedOutcomes,
                            );
                            processedOutcomes = processedOutcomes.concat(
                                processedOutcomesToAddFirst,
                            );

                            let processedOutcomesToAddSecond = this._getProcessedOutcomes(
                                selectedProviders,
                                outcome,
                                (
                                    outcome[
                                        selectedProviders[1]
                                    ] as IDistributionPercentageOutcomeLevel2
                                ).distribution,
                                processedOutcomes,
                            );
                            processedOutcomes = processedOutcomes.concat(
                                processedOutcomesToAddSecond,
                            );

                            let processedOutcomesToAddThird = this._getProcessedOutcomes(
                                selectedProviders,
                                outcome,
                                selectedProviders[2]
                                    ? (
                                          outcome[
                                              selectedProviders[2]
                                          ] as IDistributionPercentageOutcomeLevel2
                                      ).distribution
                                    : undefined,
                                processedOutcomes,
                            );
                            processedOutcomes = processedOutcomes.concat(
                                processedOutcomesToAddThird,
                            );
                        } else {
                            processedOutcomes.push(outcome);
                        }
                    });
                    return processedOutcomes.map(outcome => ({
                        outcomeId: outcome.outcomeId,
                        variableId: outcome.variableId,
                        medicalParameterId: outcome.medicalParameterId,
                        sharedProviders: outcome.sharedProviders,
                        distributionParameterL1: outcome.distributionParameterL1,
                        distributionParameterL2: outcome.distributionParameterL2,
                        distributionLevel: outcome.distributionLevel,
                        first: {
                            sourceType: selectedProvidersMultiData.provider,
                            data: outcome.multiData?.[0],
                        },
                        second: {
                            sourceType: selectedProvidersMultiData.provider,
                            data: outcome.multiData?.[1],
                        },
                        third: outcome.multiData?.[2]
                            ? {
                                  sourceType: selectedProvidersMultiData.provider,
                                  data: outcome.multiData?.[2],
                              }
                            : undefined,
                    }));
                }),
            );
        } else {
            return this.getFiltered<IOutcome[]>(
                `${registryId}/${subsetId}/${pageId}/${sectionId}/outcomes`,
                organizationId,
                filters,
                { timeRange, ...benchmarkApiParams },
            ).pipe(
                map(outcomes => {
                    let processedOutcomes: IOutcome[] = [];
                    outcomes.forEach(outcome => {
                        if (
                            outcome.distributionLevel === 2 &&
                            outcome[selectedProviders[0]]?.type === "DistributionPercentage"
                        ) {
                            let processedOutcomesToAddFirst = this._getProcessedOutcomes(
                                selectedProviders,
                                outcome,
                                (
                                    outcome[
                                        selectedProviders[0]
                                    ] as IDistributionPercentageOutcomeLevel2
                                ).distribution,
                                processedOutcomes,
                            );
                            processedOutcomes = processedOutcomes.concat(
                                processedOutcomesToAddFirst,
                            );

                            let processedOutcomesToAddSecond = this._getProcessedOutcomes(
                                selectedProviders,
                                outcome,
                                (
                                    outcome[
                                        selectedProviders[1]
                                    ] as IDistributionPercentageOutcomeLevel2
                                ).distribution,
                                processedOutcomes,
                            );
                            processedOutcomes = processedOutcomes.concat(
                                processedOutcomesToAddSecond,
                            );

                            let processedOutcomesToAddThird = this._getProcessedOutcomes(
                                selectedProviders,
                                outcome,
                                selectedProviders[2]
                                    ? (
                                          outcome[
                                              selectedProviders[2]
                                          ] as IDistributionPercentageOutcomeLevel2
                                      ).distribution
                                    : undefined,
                                processedOutcomes,
                            );
                            processedOutcomes = processedOutcomes.concat(
                                processedOutcomesToAddThird,
                            );
                        } else {
                            processedOutcomes.push(outcome);
                        }
                    });
                    return processedOutcomes.map(outcome => ({
                        outcomeId: outcome.outcomeId,
                        variableId: outcome.variableId,
                        medicalParameterId: outcome.medicalParameterId,
                        sharedProviders: outcome.sharedProviders,
                        distributionParameterL1: outcome.distributionParameterL1,
                        distributionParameterL2: outcome.distributionParameterL2,
                        distributionLevel: outcome.distributionLevel,
                        first: this._getDataOfProvider(outcome, selectedProviders, 0),
                        second: this._getDataOfProvider(outcome, selectedProviders, 1),
                        third:
                            selectedProviders.length > 2
                                ? this._getDataOfProvider(outcome, selectedProviders, 2)
                                : undefined,
                    }));
                }),
            );
        }
    }

    private _getProcessedOutcomes(
        selectedProviders: Provider[],
        outcome: IOutcome,
        data: { [optionId: string]: IDistributionPercentageOutcome } | undefined,
        existingProcessedOutcomes: IOutcome[],
    ): IOutcome[] {
        let processedOutcomes: IOutcome[] = [];

        for (var variableId in data) {
            if (!data.hasOwnProperty(variableId)) continue;

            let processedOutcome: IOutcome = {
                outcomeId: outcome.outcomeId,
                variableId,
                medicalParameterId: outcome.medicalParameterId,
                sharedProviders: outcome.sharedProviders,
                ownProvider: outcome.ownProvider,
                distributionParameterL1: outcome.distributionParameterL1,
                distributionParameterL2: outcome.distributionParameterL2,
                distributionLevel: outcome.distributionLevel,
            };

            selectedProviders.forEach(provider => {
                const data = (outcome[provider] as IDistributionPercentageOutcomeLevel2)
                    ?.distribution;
                if (data && data[variableId])
                    processedOutcome[provider] = {
                        type: data[variableId].type,
                        distribution: data[variableId].distribution,
                        anonymized: false,
                        numberOfPatients: data[variableId].numberOfPatients,
                        numberOfProviders: data[variableId].numberOfProviders,
                        distributionLevel: data[variableId].distributionLevel,
                    };
                else {
                    processedOutcome[provider] = {
                        type: "DistributionPercentage",
                        distribution: {},
                        anonymized: false,
                        numberOfPatients: 0,
                        numberOfProviders: 0,
                        distributionLevel: 1,
                    };
                }
            });

            if (
                !existingProcessedOutcomes.find(
                    item => item.variableId === processedOutcome.variableId,
                )
            )
                processedOutcomes.push(processedOutcome);
        }

        return processedOutcomes;
    }

    getOtherOutcomeProviders(
        registryId: string,
        subsetId: string,
        outcomeId: string,
        organizationId: number,
        benchmarkApiParams: BenchmarkParams,
        timeRange?: TimeRange,
        filters?: FilterParams,
    ): Observable<ReferenceOutcome[]> {
        return this.getFiltered<ReferenceOutcome[]>(
            `${registryId}/${subsetId}/${outcomeId}/outcomesotherproviders`,
            organizationId,
            filters,
            { timeRange, ...benchmarkApiParams },
        );
    }

    getOutcomeTrend(
        registryId: string,
        subsetId: string,
        outcomeId: string,
        interval: TrendInterval,
        organizationId: number,
        selectedProviders: Provider[],
        benchmarkApiParams: BenchmarkParams,
        selectedProvidersMultiData: IMultiDataSelection,
        comparisonType: IComparisonType,
        filters?: FilterParams,
    ): Observable<IOutcomeTrendUniversal[]> {
        if (comparisonType === "multiData") {
            return this.getFiltered<IOutcomeTrend[]>(
                `${registryId}/${subsetId}/${outcomeId}/trendMultiData/${interval}`,
                organizationId,
                filters,
                {
                    ...benchmarkApiParams,
                    multiDataSource:
                        providersForUrl.find(
                            provider => provider.provider === selectedProvidersMultiData.provider,
                        )?.urlParam ?? "own",
                    multiDataDimensions: selectedProvidersMultiData.dimensions.join(","),
                },
            ).pipe(
                map(outcomes =>
                    outcomes.map(outcome => ({
                        year: outcome.year,
                        quarter: outcome.quarter,
                        month: outcome.month,
                        anonymized: outcome.anonymized,
                        first: {
                            sourceType: selectedProvidersMultiData.provider,
                            data: outcome.multiData?.[0],
                        },
                        second: {
                            sourceType: selectedProvidersMultiData.provider,
                            data: outcome.multiData?.[1],
                        },
                        third: outcome.multiData?.[2]
                            ? {
                                  sourceType: selectedProvidersMultiData.provider,
                                  data: outcome.multiData?.[2],
                              }
                            : undefined,
                    })),
                ),
            );
        } else {
            return this.getFiltered<IOutcomeTrend[]>(
                `${registryId}/${subsetId}/${outcomeId}/trend/${interval}`,
                organizationId,
                filters,
                { ...benchmarkApiParams },
            ).pipe(
                map(outcomes =>
                    outcomes.map(outcome => ({
                        year: outcome.year,
                        quarter: outcome.quarter,
                        month: outcome.month,
                        anonymized: outcome.anonymized,
                        first: this._getDataOfProvider(outcome, selectedProviders, 0),
                        second: this._getDataOfProvider(outcome, selectedProviders, 1),
                        third:
                            selectedProviders.length > 2
                                ? this._getDataOfProvider(outcome, selectedProviders, 2)
                                : undefined,
                    })),
                ),
            );
        }
    }

    getEventAnalysis(
        registryId: string,
        subsetId: string,
        outcomeId: string,
        organizationId: number,
        benchmarkApiParams: BenchmarkParams,
        selectedProviders: Provider[],
        selectedProvidersMultiData: IMultiDataSelection,
        comparisonType: IComparisonType,
        timeRange?: TimeRange,
        filters?: FilterParams,
    ): Observable<IEventAnalysisUniversal> {
        if (comparisonType === "multiData") {
            return this.getFiltered<IEventAnalysis>(
                `${registryId}/${subsetId}/${outcomeId}/eventAnalysisMultiData`,
                organizationId,
                filters,
                {
                    timeRange,
                    ...benchmarkApiParams,
                    multiDataSource:
                        providersForUrl.find(
                            provider => provider.provider === selectedProvidersMultiData.provider,
                        )?.urlParam ?? "own",
                    multiDataDimensions: selectedProvidersMultiData.dimensions.join(","),
                },
            ).pipe(
                map(event => {
                    return {
                        eventName: event.eventName,
                        intervals: event.intervals.map(interval => ({
                            interval: interval.interval,
                            first: {
                                sourceType: selectedProvidersMultiData.provider,
                                data: interval.multiData?.[0],
                            },
                            second: {
                                sourceType: selectedProvidersMultiData.provider,
                                data: interval.multiData?.[1],
                            },
                            third: interval.multiData?.[2]
                                ? {
                                      sourceType: selectedProvidersMultiData.provider,
                                      data: interval.multiData?.[2],
                                  }
                                : undefined,
                        })),
                    };
                }),
            );
        } else {
            return this.getFiltered<IEventAnalysis>(
                `${registryId}/${subsetId}/${outcomeId}/eventAnalysis`,
                organizationId,
                filters,
                { timeRange, ...benchmarkApiParams },
            ).pipe(
                map(event => {
                    return {
                        eventName: event.eventName,
                        intervals: event.intervals.map(interval => ({
                            interval: interval.interval,
                            first: this._getEventAnalysisWithSource(interval, selectedProviders, 0),
                            second: this._getEventAnalysisWithSource(
                                interval,
                                selectedProviders,
                                1,
                            ),
                            third: this._getEventAnalysisWithSource(interval, selectedProviders, 2),
                        })),
                    };
                }),
            );
        }
    }

    getSankey(
        registryId: string,
        subsetId: string,
        outcomeId: string,
        organizationId: number,
        selectedProviders: Provider[],
        benchmarkApiParams: BenchmarkParams,
        selectedProvidersMultiData: IMultiDataSelection,
        multiDataIndex: number,
        outcomeCode: string | undefined,
        comparisonType: IComparisonType,
        timeRange?: TimeRange,
        filters?: FilterParams,
    ): Observable<ISankeyUniversal> {
        const multiDataProvider =
            comparisonType === "multiData" ? selectedProvidersMultiData.provider : undefined;

        return this.getFiltered<ISankey>(
            `${registryId}/${subsetId}/${outcomeId}/outcome/sankey`,
            organizationId,
            this.mergeFilters(
                filters,
                undefined,
                undefined,
                comparisonType === "multiData" && outcomeCode
                    ? {
                          type: "string",
                          value: selectedProvidersMultiData.dimensions[multiDataIndex],
                          id: outcomeCode,
                      }
                    : undefined,
            ),
            { timeRange, ...benchmarkApiParams },
        ).pipe(
            map(outcome => {
                return {
                    first: this._getSankeyWithSource(
                        outcome,
                        selectedProviders,
                        0,
                        multiDataProvider,
                    ),
                    second: this._getSankeyWithSource(
                        outcome,
                        selectedProviders,
                        1,
                        multiDataProvider,
                    ),
                    third:
                        selectedProviders.length > 2 ||
                        (comparisonType === "multiData" &&
                            selectedProvidersMultiData.dimensions.length > 2)
                            ? this._getSankeyWithSource(
                                  outcome,
                                  selectedProviders,
                                  2,
                                  multiDataProvider,
                              )
                            : undefined,
                };
            }),
        );
    }

    getFilterComparisons(
        registryId: string,
        subsetId: string,
        outcomeId: string,
        filterGroupId: string,
        organizationId: number,
        selectedProviders: Provider[],
        benchmarkApiParams: BenchmarkParams,
        selectedProvidersMultiData: IMultiDataSelection,
        comparisonType: IComparisonType,
        timeRange?: TimeRange,
        measurementMoment?: string,
        filters?: FilterParams,
    ): Observable<FilterComparisonUniversal[]> {
        if (comparisonType === "multiData") {
            return this.getFiltered<FilterComparison[]>(
                `${registryId}/${subsetId}/${outcomeId}/filtercomparisonMultiData/${filterGroupId}`,
                organizationId,
                filters,
                {
                    timeRange,
                    measurementMoment,
                    ...benchmarkApiParams,
                    multiDataSource:
                        providersForUrl.find(
                            provider => provider.provider === selectedProvidersMultiData.provider,
                        )?.urlParam ?? "own",
                    multiDataDimensions: selectedProvidersMultiData.dimensions.join(","),
                },
            ).pipe(
                map(outcomes =>
                    outcomes.map(outcome => ({
                        filterType: outcome.filterType,
                        filterName: outcome.filterName,
                        comparisonData: outcome.comparisonData.map(comparison => ({
                            filterValue: comparison.filterValue,
                            outcome: {
                                first: {
                                    sourceType: selectedProvidersMultiData.provider,
                                    data: comparison.outcome.multiData?.[0],
                                },
                                second: {
                                    sourceType: selectedProvidersMultiData.provider,
                                    data: comparison.outcome.multiData?.[1],
                                },
                                third: comparison.outcome.multiData?.[2]
                                    ? {
                                          sourceType: selectedProvidersMultiData.provider,
                                          data: comparison.outcome.multiData?.[2],
                                      }
                                    : undefined,
                            },
                        })),
                    })),
                ),
            );
        } else {
            return this.getFiltered<FilterComparison[]>(
                `${registryId}/${subsetId}/${outcomeId}/filtercomparison/${filterGroupId}`,
                organizationId,
                filters,
                {
                    timeRange,
                    measurementMoment,
                    ...benchmarkApiParams,
                },
            ).pipe(
                map(outcomes =>
                    outcomes.map(outcome => ({
                        filterType: outcome.filterType,
                        filterName: outcome.filterName,
                        comparisonData: outcome.comparisonData.map(comparison => ({
                            filterValue: comparison.filterValue,
                            outcome: {
                                first: this._getDataOfProvider(
                                    comparison.outcome,
                                    selectedProviders,
                                    0,
                                ),
                                second: this._getDataOfProvider(
                                    comparison.outcome,
                                    selectedProviders,
                                    1,
                                ),
                                third:
                                    selectedProviders.length > 2
                                        ? this._getDataOfProvider(
                                              comparison.outcome,
                                              selectedProviders,
                                              2,
                                          )
                                        : undefined,
                            },
                        })),
                    })),
                ),
            );
        }
    }

    /**
     * Patient Counts
     */
    getPatientsCount(
        registryId: string,
        subsetId: string,
        organizationId: number,
        selectedProvidersMultiData: IMultiDataSelection,
        comparisonType: IComparisonType,
        timeRange?: TimeRange,
        filters?: FilterParams,
    ): Observable<IPatientCount | IPatientsCountMultiData> {
        if (comparisonType === "multiData") {
            return this.getFiltered<IPatientsCountMultiData>(
                `${registryId}/${subsetId}/patientscountMultiData`,
                organizationId,
                filters,
                {
                    timeRange,
                    multiDataSource:
                        providersForUrl.find(
                            provider => provider.provider === selectedProvidersMultiData.provider,
                        )?.urlParam ?? "own",
                    multiDataDimensions: selectedProvidersMultiData.dimensions.join(","),
                },
            );
        } else {
            return this.getFiltered<IPatientCount>(
                `${registryId}/${subsetId}/patientscount`,
                organizationId,
                filters,
                {
                    timeRange,
                },
            );
        }
    }

    getPatientTrend(
        registryId: string,
        subsetId: string,
        interval: TrendInterval,
        organizationId: number,
        filters?: FilterParams,
    ): Observable<ITrendMoment[]> {
        return this.getFiltered<ITrendMoment[]>(
            `${registryId}/${subsetId}/patientscount/trend/${interval}`,
            organizationId,
            filters,
        );
    }

    /**
     * Patient List
     */
    getPatientsList(
        registryId: string,
        subsetId: string,
        organizationId: number,
        timeRange?: TimeRange,
        filters?: FilterParams,
        offset?: number,
        count?: number,
        sortBy?: string,
        sortType?: ISortDirection,
    ): Observable<PatientList> {
        return this.getFiltered<PatientList>(
            `${registryId}/${subsetId}/patientslist`,
            organizationId,
            filters,
            {
                timeRange,
                offset,
                count,
                sortBy,
                sortType,
            },
        );
    }

    /**
     * Patient export
     */
    getPatientExport(
        registryId: string,
        subsetId: string,
        organizationId: number,
        params: {
            language: string;
        },
        timeRange: TimeRange,
        filters?: FilterParams,
    ): Observable<HttpResponse<Blob>> {
        let filtersQuery: Record<string, string> = {};
        if (filters?.length) {
            filtersQuery = this._getFiltersQueryParamsString(filters, "searchCriteria");
        }
        let otherParams: Record<string, string> = {};
        if (
            timeRange?.minYear !== null &&
            timeRange?.maxYear !== null &&
            timeRange?.minMonth !== null &&
            timeRange?.maxMonth
        ) {
            otherParams = this._getOtherQueryParams({ timeRange });
        }
        return this._get<Blob>(`${registryId}/${subsetId}/patientslist/report`, {
            params: {
                ...params,
                ...filtersQuery,
                ...otherParams,
            },
            headers: {
                [ORG_ID_HEADER]: organizationId.toString(),
            },
            responseType: "blob",
            observe: "response",
        });
    }

    /**
     * Data for export
     */
    getDataExport(
        registryId: string,
        subsetId: string,
        pageId: string | undefined,
        trendsInterval: TrendInterval,
        organizationId: number,
        selectedProviders: Provider[],
        benchmarkApiParams: BenchmarkParams,
        sections: string[],
        exportTrends: boolean,
        selectedFilterSplit: SelectedFilterSplits | null,
        selectedProvidersMultiData: IMultiDataSelection,
        comparisonType: IComparisonType,
        outcomeCode: string | undefined,
        timeRange?: TimeRange,
        filters?: FilterParams,
    ): Observable<IDataExportSectionUniversal[]> {
        const sectionsWithSplit: Record<string, string | null> = {};
        sections.forEach(section => {
            const sectionKey = `sectionsWithSplit[${section}]`;
            sectionsWithSplit[sectionKey] =
                selectedFilterSplit?.[section]?.filterSplitValue ?? null;
        });

        const nonFilterParams = {
            timeRange,
            pageId,
            trendsInterval,
            exportTrends,
            ...sectionsWithSplit,
            ...benchmarkApiParams,
        };
        const url = `${registryId}/${subsetId}/dataexport`;

        if (comparisonType === "multiData" && outcomeCode) {
            return forkJoin([
                selectedProvidersMultiData.dimensions.length > 0
                    ? this.getFiltered<IDataExportSection[]>(
                          url,
                          organizationId,
                          this.mergeFilters(filters, undefined, undefined, {
                              type: "string",
                              value: selectedProvidersMultiData.dimensions[0],
                              id: outcomeCode,
                          }),
                          nonFilterParams,
                      )
                    : of(null),
                selectedProvidersMultiData.dimensions.length > 1
                    ? this.getFiltered<IDataExportSection[]>(
                          url,
                          organizationId,
                          this.mergeFilters(filters, undefined, undefined, {
                              type: "string",
                              value: selectedProvidersMultiData.dimensions[1],
                              id: outcomeCode,
                          }),
                          nonFilterParams,
                      )
                    : of(null),
                selectedProvidersMultiData.dimensions.length > 2
                    ? this.getFiltered<IDataExportSection[]>(
                          url,
                          organizationId,
                          this.mergeFilters(filters, undefined, undefined, {
                              type: "string",
                              value: selectedProvidersMultiData.dimensions[2],
                              id: outcomeCode,
                          }),
                          nonFilterParams,
                      )
                    : of(null),
            ]).pipe(
                map((results: (IDataExportSection[] | null)[]) => {
                    return results[0]
                        ? results[0].map((section, sectionId) => {
                              const outcomes = section.outcomes.map((outcome, outcomeId) => ({
                                  id: outcome.id,
                                  outcome: {
                                      unit: outcome.outcome.unit,
                                      calculationType: outcome.outcome.calculationType,
                                      formatter: outcome.outcome.formatter,
                                      option: outcome.outcome.option,
                                      outcomeId: outcome.outcome.outcomeId,
                                      sharedProviders: outcome.outcome.sharedProviders,
                                      medicalParameterId: outcome.outcome.medicalParameterId,
                                      maxValue: outcome.outcome.maxValue,
                                      visualType: outcome.outcome.visualType,
                                      decimalPoints: outcome.outcome.decimalPoints,
                                      outcomeType: outcome.outcome.outcomeType,
                                      funnelPlotConfidenceInterval:
                                          outcome.outcome.funnelPlotConfidenceInterval,

                                      first: this._getDataOfProvider(
                                          outcome.outcome,
                                          selectedProviders,
                                          0,
                                          selectedProvidersMultiData.provider,
                                      ),
                                      second: results[1]?.[sectionId].outcomes[outcomeId].outcome
                                          ? this._getDataOfProvider(
                                                results[1][sectionId].outcomes[outcomeId].outcome,
                                                selectedProviders,
                                                1,
                                                selectedProvidersMultiData.provider,
                                            )
                                          : undefined,
                                      third: results[2]?.[sectionId].outcomes[outcomeId].outcome
                                          ? this._getDataOfProvider(
                                                results[2][sectionId].outcomes[outcomeId].outcome,
                                                selectedProviders,
                                                2,
                                                selectedProvidersMultiData.provider,
                                            )
                                          : undefined,
                                  },

                                  trend: outcome.trend.map((trend, trendId) => ({
                                      year: trend.year,
                                      quarter: trend.quarter,
                                      month: trend.month,
                                      anonymized: trend.anonymized,
                                      first: this._getDataOfProvider(
                                          trend,
                                          selectedProviders,
                                          0,
                                          selectedProvidersMultiData.provider,
                                      ),
                                      second: results[1]?.[sectionId].outcomes[outcomeId].trend[
                                          trendId
                                      ]
                                          ? this._getDataOfProvider(
                                                results[1][sectionId].outcomes[outcomeId].trend[
                                                    trendId
                                                ],
                                                selectedProviders,
                                                1,
                                                selectedProvidersMultiData.provider,
                                            )
                                          : undefined,
                                      third: results[2]?.[sectionId].outcomes[outcomeId].trend[
                                          trendId
                                      ]
                                          ? this._getDataOfProvider(
                                                results[2][sectionId].outcomes[outcomeId].trend[
                                                    trendId
                                                ],
                                                selectedProviders,
                                                2,
                                                selectedProvidersMultiData.provider,
                                            )
                                          : undefined,
                                  })),
                                  confidenceType: outcome.confidenceType,
                                  otherProviders: outcome.otherProviders,
                              }));
                              let filteredOutcomes: IDataExportOutcomeUniversal[] = [];
                              outcomes.forEach(item => {
                                  if (
                                      this._hasData(
                                          item,
                                          item.outcome.first.data?.type as CalculationType,
                                      )
                                  ) {
                                      filteredOutcomes.push(item);
                                  }
                              });
                              return {
                                  name: section.name,
                                  outcomes: filteredOutcomes,
                              };
                          })
                        : [];
                }),
            );
        } else {
            return this.getFiltered<IDataExportSection[]>(
                url,
                organizationId,
                filters,
                nonFilterParams,
            ).pipe(
                map(sections =>
                    sections.map(section => {
                        const outcomes = section.outcomes.map(outcome => ({
                            id: outcome.id,
                            outcome: {
                                unit: outcome.outcome.unit,
                                calculationType: outcome.outcome.calculationType,
                                formatter: outcome.outcome.formatter,
                                option: outcome.outcome.option,
                                outcomeId: outcome.outcome.outcomeId,
                                sharedProviders: outcome.outcome.sharedProviders,
                                medicalParameterId: outcome.outcome.medicalParameterId,
                                maxValue: outcome.outcome.maxValue,
                                visualType: outcome.outcome.visualType,
                                decimalPoints: outcome.outcome.decimalPoints,
                                outcomeType: outcome.outcome.outcomeType,
                                funnelPlotConfidenceInterval:
                                    outcome.outcome.funnelPlotConfidenceInterval,

                                first: this._getDataOfProvider(
                                    outcome.outcome,
                                    selectedProviders,
                                    0,
                                ),
                                second: this._getDataOfProvider(
                                    outcome.outcome,
                                    selectedProviders,
                                    1,
                                ),
                                third:
                                    selectedProviders.length > 2
                                        ? this._getDataOfProvider(
                                              outcome.outcome,
                                              selectedProviders,
                                              2,
                                          )
                                        : undefined,
                            },

                            trend: outcome.trend.map(trend => ({
                                year: trend.year,
                                quarter: trend.quarter,
                                month: trend.month,
                                anonymized: trend.anonymized,
                                first: this._getDataOfProvider(trend, selectedProviders, 0),
                                second: this._getDataOfProvider(trend, selectedProviders, 1),
                                third:
                                    selectedProviders.length > 2
                                        ? this._getDataOfProvider(trend, selectedProviders, 2)
                                        : undefined,
                            })),
                            confidenceType: outcome.confidenceType,
                            otherProviders: outcome.otherProviders,
                        }));
                        let filteredOutcomes: IDataExportOutcomeUniversal[] = [];
                        outcomes.forEach(item => {
                            if (
                                this._hasData(
                                    item,
                                    item.outcome.first.data?.type as CalculationType,
                                )
                            ) {
                                filteredOutcomes.push(item);
                            }
                        });
                        return {
                            name: section.name,
                            outcomes: filteredOutcomes,
                        };
                    }),
                ),
            );
        }
    }

    private _hasData(
        outcome: IDataExportOutcomeUniversal,
        calculationType: CalculationType,
    ): boolean {
        if (
            calculationType === "DistributionPercentage" ||
            calculationType === "DistributionMedian" ||
            calculationType === "DistributionAverage"
        ) {
            [
                outcome.outcome.first.data,
                outcome.outcome.second?.data,
                outcome.outcome.third?.data,
            ].forEach(data => {
                const distribution = (data as IDistributionOutcome)?.distribution;
                if (distribution) {
                    for (const [key, value] of Object.entries(distribution)) {
                        if (
                            this._areAllDistributionSourcesEmpty(value.type, outcome.outcome, key)
                        ) {
                            const first =
                                (outcome.outcome.first?.data as IDistributionOutcome)
                                    ?.distribution ?? {};
                            if (first[key]) delete first[key];
                            const second =
                                (outcome.outcome.second?.data as IDistributionOutcome)
                                    ?.distribution ?? {};
                            if (second[key]) delete second[key];
                            const third =
                                (outcome.outcome.third?.data as IDistributionOutcome)
                                    ?.distribution ?? {};
                            if (third[key]) delete third[key];
                        }
                    }
                }
            });

            return (
                !_.isEmpty((outcome.outcome.first.data as IDistributionOutcome)?.distribution) ||
                !_.isEmpty((outcome.outcome.second?.data as IDistributionOutcome)?.distribution) ||
                !_.isEmpty((outcome.outcome.third?.data as IDistributionOutcome)?.distribution)
            );
        }
        return !(
            (!outcome.outcome.first.data ||
                this._isChartEmpty(calculationType, outcome.outcome.first.data)) &&
            (!outcome.outcome.second?.data ||
                this._isChartEmpty(calculationType, outcome.outcome.second.data)) &&
            (!outcome.outcome.third?.data ||
                this._isChartEmpty(calculationType, outcome.outcome.third.data))
        );
    }

    private _areAllDistributionSourcesEmpty(
        calculationType: CalculationType,
        outcome: IOutcomeUniversal,
        key: string,
    ): boolean {
        const first = (outcome.first?.data as IDistributionOutcome)?.distribution ?? {};
        const second = (outcome.second?.data as IDistributionOutcome)?.distribution ?? {};
        const third = (outcome.third?.data as IDistributionOutcome)?.distribution ?? {};

        return (
            this._isChartEmpty(calculationType, first[key]) &&
            this._isChartEmpty(calculationType, second[key]) &&
            this._isChartEmpty(calculationType, third[key])
        );
    }

    private _isChartEmpty(
        calculationType: CalculationType,
        outcome: IDistributionPercentage | IDistributionMedian | IDistributionAverage,
    ): boolean {
        if (calculationType === "Average") {
            return outcome === undefined || (outcome as IDistributionAverage).average === undefined;
        }

        if (calculationType === "Median") {
            return outcome === undefined || (outcome as IDistributionMedian).median === undefined;
        }

        if (calculationType === "Percentage") {
            return (
                outcome === undefined ||
                (outcome as IDistributionPercentage).percentage === undefined
            );
        }

        return false;
    }

    getSectionFilters(
        registryId: string,
        subsetId: string,
        pageId: string,
        sectionId: string,
        organizationId: number,
        filters?: FilterParams,
    ): Observable<ISectionFilter> {
        return this.getFiltered<ISectionFilter>(
            `${registryId}/${subsetId}/${pageId}/${sectionId}/filters`,
            organizationId,
            filters,
        );
    }

    getDistributions(
        registryId: string,
        subsetId: string,
        organizationId: number,
        distributionParameter: string,
        filters?: FilterParams,
    ): Observable<IDistribution> {
        return this.getFiltered<IDistribution>(
            `${registryId}/${subsetId}/${distributionParameter}/distribution`,
            organizationId,
            filters,
        );
    }

    /**
     * Other
     */
    getFiltered<T>(
        url: string,
        organizationId: number,
        filters?: FilterParams,
        nonFilterParams?: NonFilterParams,
    ): Observable<T> {
        let filtersQuery: Record<string, string> = {};
        let otherParams: Record<string, string> = {};
        const otherQueryParams = { ...nonFilterParams };
        if (nonFilterParams) {
            otherParams = this._getOtherQueryParams(otherQueryParams);
        }

        if (filters?.length) {
            filtersQuery = this._getFiltersQueryParamsString(filters, "searchCriteria");
        }

        return this._get<T>(url, {
            headers: {
                [ORG_ID_HEADER]: organizationId.toString(),
            },
            params: {
                ...filtersQuery,
                ...otherQueryParams,
                ...otherParams,
            },
        });
    }

    private _getOtherQueryParams(otherParams: NonFilterParams): Record<string, string> {
        const params: Record<string, string> = {};

        const connectBenchmarkProviders = otherParams.connectBenchmarkProviders;
        if (connectBenchmarkProviders) {
            const paramValue = this._getCategoryQueryString({
                type: "category",
                id: "connectBenchmarkProviders",
                values: connectBenchmarkProviders,
            });
            params["connectBenchmarkProviders"] = paramValue;
            delete otherParams.connectBenchmarkProviders;
        }

        const regions = otherParams.regions;
        if (regions) {
            const paramValue = this._getCategoryQueryString({
                type: "category",
                id: "regions",
                values: [regions],
            });
            params["regions"] = paramValue;
            delete otherParams.regions;
        }

        const years = otherParams.timeRange;
        if (years?.minYear && years?.maxYear && years?.minMonth && years?.maxMonth) {
            const paramValue = this._getTimeRangeQueryString({
                type: "timeRange",
                id: "timeRange",
                minYear: years.minYear,
                maxYear: years.maxYear,
                minMonth: years.minMonth,
                maxMonth: years.maxMonth,
            });
            params["timeRange"] = paramValue;
        }
        if (years) {
            delete otherParams.timeRange;
        }

        const providerType = otherParams.providerType;
        if (providerType) {
            const paramValue = this._getStringQueryString({
                type: "string",
                id: "providerType",
                value: providerType,
            });
            params["providerType"] = paramValue;
            delete otherParams.providerType;
        }

        return params;
    }

    private _getFiltersQueryParamsString(
        params: FilterParams,
        dictionaryName: string,
    ): Record<string, string> {
        const filterParams: Record<string, string> = {};
        params.forEach(param => {
            let filterValue: string;
            switch (param.type) {
                case "category":
                    filterValue = this._getCategoryQueryString(param);
                    break;
                case "range":
                    filterValue = this._getRangeQueryString(param);
                    break;
                case "string":
                    filterValue = this._getStringQueryString(param);
                    break;
                case "number":
                    filterValue = this._getNumberQueryString(param);
                    break;
                default:
                    filterValue = "";
            }
            const filterKey = `${dictionaryName}[${param.id}]`;
            filterParams[filterKey] = filterValue;
        });

        return filterParams;
    }

    private _getCategoryQueryString(params: CategoryQueryParams): string {
        const filterValuesQuery = params.values.join(SEPARATORS.values);
        return filterValuesQuery;
    }

    private _getRangeQueryString(params: RangeQueryParams): string {
        const filterValuesQuery = params.min + SEPARATORS.range + params.max;
        return filterValuesQuery;
    }

    private _getTimeRangeQueryString(params: TimeRangeQueryParams): string {
        const filterValuesQuery =
            params.minYear +
            SEPARATORS.items +
            params.minMonth +
            SEPARATORS.range +
            params.maxYear +
            SEPARATORS.items +
            params.maxMonth;
        return filterValuesQuery;
    }

    private _getStringQueryString(params: StringQueryParams): string {
        return params.value;
    }

    private _getNumberQueryString(params: NumberQueryParams): string {
        return params.value.toString();
    }

    /**
     * Status
     */
    getStatus(): Observable<IStatus> {
        return this._get<IStatus>(`/`);
    }

    private _getDataOfProvider(
        outcome: IOutcome | IFilterOption | IOutcomeTrend,
        selectedProviders: Provider[],
        order: number,
        multiDataProvider?: Provider,
    ): IOutcomeWithSource {
        const provider = multiDataProvider
            ? multiDataProvider
            : selectedProviders.length > order
              ? selectedProviders[order]
              : defaultProviders[order];

        if (provider === "ownProvider" && outcome.ownProvider !== undefined) {
            return {
                sourceType: "ownProvider",
                data: outcome.ownProvider ?? undefined,
            };
        } else if (provider === "regionalBenchmark" && outcome.regionalBenchmark !== undefined) {
            return {
                sourceType: "regionalBenchmark",
                data: outcome.regionalBenchmark,
            };
        } else if (
            provider === "providerTypeBenchmark" &&
            outcome.providerTypeBenchmark !== undefined
        ) {
            return {
                sourceType: "providerTypeBenchmark",
                data: outcome.providerTypeBenchmark,
            };
        } else if (provider === "connectBenchmark" && outcome.connectBenchmark !== undefined) {
            return {
                sourceType: "connectBenchmark",
                data: outcome.connectBenchmark,
            };
        } else if (
            provider === "internationalBenchmark" &&
            outcome.internationalBenchmark !== undefined
        ) {
            return {
                sourceType: "internationalBenchmark",
                data: outcome.internationalBenchmark,
            };
        } else {
            return {
                sourceType: "nationalBenchmark",
                data: outcome.nationalBenchmark,
            };
        }
    }

    private _getEventAnalysisWithSource(
        interval: IEventAnalysisInterval,
        selectedProviders: Provider[],
        order: number,
    ): IEventAnalysisIntervalReferenceWithSource {
        const provider =
            selectedProviders.length > order ? selectedProviders[order] : defaultProviders[order];

        if (provider === "ownProvider" && interval.ownProvider !== undefined) {
            return {
                sourceType: "ownProvider",
                data: interval.ownProvider ?? undefined,
            };
        } else if (
            provider === "providerTypeBenchmark" &&
            interval.providerTypeBenchmark !== undefined
        ) {
            return {
                sourceType: "providerTypeBenchmark",
                data: interval.providerTypeBenchmark,
            };
        } else if (provider === "connectBenchmark" && interval.connectBenchmark !== undefined) {
            return {
                sourceType: "connectBenchmark",
                data: interval.connectBenchmark,
            };
        } else {
            return {
                sourceType: "nationalBenchmark",
                data: interval.nationalBenchmark,
            };
        }
    }

    private _getSankeyWithSource(
        outcome: ISankey,
        selectedProviders: Provider[],
        order: number,
        multiDataProvider?: Provider,
    ): IOutcomeWithSource {
        const provider = multiDataProvider
            ? multiDataProvider
            : selectedProviders.length > order
              ? selectedProviders[order]
              : defaultProviders[order];

        if (provider === "ownProvider" && outcome.ownProvider !== undefined) {
            return {
                sourceType: "ownProvider",
                data: (outcome.ownProvider as ReferenceOutcome) ?? undefined,
            };
        } else if (provider === "regionalBenchmark" && outcome.regionalBenchmark !== undefined) {
            return {
                sourceType: "regionalBenchmark",
                data: outcome.regionalBenchmark as ReferenceOutcome,
            };
        } else if (
            provider === "providerTypeBenchmark" &&
            outcome.providerTypeBenchmark !== undefined
        ) {
            return {
                sourceType: "providerTypeBenchmark",
                data: outcome.providerTypeBenchmark as ReferenceOutcome,
            };
        } else if (provider === "connectBenchmark" && outcome.connectBenchmark !== undefined) {
            return {
                sourceType: "connectBenchmark",
                data: outcome.connectBenchmark as ReferenceOutcome,
            };
        } else if (
            provider === "internationalBenchmark" &&
            outcome.internationalBenchmark !== undefined
        ) {
            return {
                sourceType: "internationalBenchmark",
                data: outcome.internationalBenchmark as ReferenceOutcome,
            };
        } else {
            return {
                sourceType: "nationalBenchmark",
                data: outcome.nationalBenchmark as ReferenceOutcome,
            };
        }
    }

    mergeFilters(
        filters: FilterParam[] | undefined,
        selectedFilterSplit?: FilterSplit,
        selectedDistributionLevel2?: { id: string | undefined; value: string | undefined },
        multiDataFilter?: FilterParam | undefined,
    ): FilterParams | undefined {
        let filtersWithSplit = filters ? [...filters] : [];
        if (multiDataFilter) {
            filtersWithSplit.push(multiDataFilter);
        }
        if (selectedFilterSplit?.filterSplitValue) {
            filtersWithSplit?.push({
                id: selectedFilterSplit.filterSplitId,
                type: "category",
                values: [selectedFilterSplit.filterSplitValue],
            });
        }
        if (
            selectedDistributionLevel2?.id &&
            selectedDistributionLevel2?.id !== EMPTY_PARAMETER &&
            selectedDistributionLevel2?.value &&
            selectedDistributionLevel2?.value !== EMPTY_PARAMETER
        ) {
            filtersWithSplit?.push({
                id: selectedDistributionLevel2.id,
                type: "category",
                values: [selectedDistributionLevel2.value],
            });
        }
        return filtersWithSplit.length ? filtersWithSplit : undefined;
    }
}
