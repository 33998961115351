import { ComponentType } from "@angular/cdk/portal";
import { ChangeDetectorRef, Component, Injectable, OnInit, inject } from "@angular/core";

import {
    FilterRendererComponentBase,
    IFilterDefinition,
    IFilterRenderer,
    IFilterRendererFactory,
    LgFilterSet,
} from "@logex/framework/lg-filterset";

import { BaseRangeRenderer, Range } from "@codman/shared/util-filters";

import { IYearFilterDefinition, YearFilterStorage } from "./year-filter.types";

export class YearFilterRenderer
    extends BaseRangeRenderer<IYearFilterDefinition>
    implements IFilterRenderer
{
    getFilterLineComponent(): ComponentType<
        FilterRendererComponentBase<IYearFilterDefinition, YearFilterRenderer>
    > {
        return YearFilterRendererLineComponent;
    }

    getPopupComponent(): ComponentType<
        FilterRendererComponentBase<IYearFilterDefinition, YearFilterRenderer>
    > {
        return YearFilterRendererLineComponent;
    }
}

// Factory ---------------------------------------------------------------------------------------------------------
@Injectable()
export class YearFilterRendererFactory implements IFilterRendererFactory {
    readonly name: string = "year";

    create(
        definition: IYearFilterDefinition,
        filters: YearFilterStorage,
        filterDefinitions: IFilterDefinition[],
        filterSet: LgFilterSet,
    ): IFilterRenderer {
        return new YearFilterRenderer(definition, filters, filterDefinitions, filterSet);
    }
}

// Line template  --------------------------------------------------------------------------------------------------
@Component({
    selector: "year-filter-renderer-line",
    template: `<codman-year-filter
        [min]="_definition.min"
        [max]="_definition.max"
        [registry]="_definition.registry"
        [subset]="_definition.subset"
        [range]="_filters[_definition.storage || '']"
        (didChange)="_emitChange($event)"
    ></codman-year-filter> `,
    standalone: false,
})
export class YearFilterRendererLineComponent
    extends FilterRendererComponentBase<IYearFilterDefinition, YearFilterRenderer>
    implements OnInit
{
    private _cdRef = inject(ChangeDetectorRef);

    ngOnInit(): void {
        Promise.resolve().then(() => {
            const selectedYears = localStorage.getItem("years");
            const parsedYears = JSON.parse(selectedYears ?? "{}");
            if (
                parsedYears?.min &&
                parsedYears?.max &&
                this._definition.min <= parsedYears.min &&
                this._definition.max >= parsedYears.max &&
                parsedYears?.registry === this._definition.registry
            ) {
                this._renderer.update(parsedYears.min, parsedYears.max);
            }

            this._cdRef.markForCheck();
        });
    }

    _emitChange(range: Range): void {
        this._renderer.update(range.min, range.max);
        this._triggerChange();
    }
}
