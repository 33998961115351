import { Observable, combineLatest, of } from "rxjs";
import { debounceTime, map } from "rxjs/operators";

import { BenchmarkType } from "@codman/shared/feature-benchmarking";

import {
    FilterParam,
    FilterParams,
    FilterSplits,
    IOutcome,
    IPatientCount,
    IRegistryStructure,
    ISubsetConfiguration,
    ITrendMoment,
    MedicalItem,
    OutcomeConfiguration,
    OutcomeWithConfiguration,
    Regions,
} from "./data-access-api.types";
import {
    FullNavigationState,
    IRegistryStructureLookup,
    NavigationState,
} from "./data-access-state.service";
import {
    fakeBenchmarkConfiguration,
    fakeFilters,
    fakeMedicalParameters,
    fakeOutcomesConfiguration,
    fakeRegistryConfiguration,
    fakeRegistryStructure,
    fakeSharedProviders,
    fakeSubsetConfiguration,
    fakeYearsFilterCount,
} from "./fake-api";
import { EMPTY_PARAMETER } from "libs/exploration/util-translating/src/lib/outcome-label.pipe";

export class DataAccessStateMockService {
    readonly currentRegistry$ = of("Dato");
    readonly currentSubset$ = of("bariatricSurgery");
    readonly currentPage$ = of("clinicalOutcomes");
    readonly connectProviders$ = of(undefined);
    readonly regions$ = of(undefined);
    readonly selectedBenchmark$ = of("national");
    readonly benchmarkProviderType$ = of(undefined);
    readonly benchmarkApiParams$ = of(undefined);

    readonly navigationState$: Observable<NavigationState> = combineLatest([
        this.currentRegistry$,
        this.currentSubset$,
    ]).pipe(
        debounceTime(10),
        map(([registryId, subsetId]) => ({
            registryId,
            subsetId,
        })),
    );

    readonly fullNavigationState$: Observable<FullNavigationState> = combineLatest([
        this.currentRegistry$,
        this.currentSubset$,
        this.currentPage$,
    ]).pipe(
        debounceTime(10),
        map(([registryId, subsetId, pageId]) => ({
            registryId,
            subsetId,
            pageId,
        })),
    );

    readonly registryStructure$ = of(fakeRegistryStructure);

    readonly registryConfiguration$ = of(fakeRegistryConfiguration);

    readonly registryStructureLookup$ = this.registryStructure$.pipe(
        map(structure => this._getRegistryStructureLookup(structure)),
    );

    readonly medicalParameters$ = of(fakeMedicalParameters);

    readonly filters$ = of(fakeFilters);

    readonly subsetConfiguration$ = of(fakeSubsetConfiguration);

    readonly yearFilter$ = of(fakeYearsFilterCount);

    readonly outcomesConfiguration$ = of(fakeOutcomesConfiguration);

    readonly sharedProviders$ = of(fakeSharedProviders);

    readonly registryPermissions$ = of(["read", "read_patients"]);

    readonly benchmarkConfiguration$ = of(fakeBenchmarkConfiguration);

    setCurrentRegistry(id: string): void {
        //
    }

    setCurrentSubset(subsetId: string): void {
        //
    }

    setCurrentPage(pageId: string): void {
        //
    }

    updateConnectProviders(providers?: number[]): void {
        //
    }

    updateSelectedBenchmark(selectedBenchmark: BenchmarkType): void {
        //
    }

    updateBenchmarkProviderType(benchmarkProviderType?: string): void {
        //
    }

    updateRegions(regions: Regions): void {
        //
    }

    mergeConfigurationInOutcomes(
        outcome: IOutcome,
        subsetConfiguration: ISubsetConfiguration,
        medicalParameter?: MedicalItem,
        outcomeConfiguration?: OutcomeConfiguration,
    ): OutcomeWithConfiguration {
        return {
            ...outcome,
            calculationType: "Percentage",
            formatter: "percent",
            unit: "",
            ...outcomeConfiguration,
        };
    }

    getPatientsCount$(): Observable<IPatientCount> {
        return of({
            numerator: 10,
            denominator: 10,
            connectBenchmarkNumerator: 10,
            connectBenchmarkDenominator: 10,
            nationalBenchmarkNumerator: 10,
            nationalBenchmarkDenominator: 10,
            providerTypeBenchmarkNumerator: 10,
            providerTypeBenchmarkDenominator: 10,
        });
    }

    getPatientTrend$(): Observable<ITrendMoment[]> {
        return of([]);
    }

    private _getRegistryStructureLookup(structure: IRegistryStructure): IRegistryStructureLookup {
        const lookup = structure.subsets.reduce(
            (lookup, subset) => {
                lookup[subset.id] = subset.pages.reduce(
                    (pagesLookup, page) => {
                        pagesLookup[page.id] = page.sections.map(section => section.id);
                        return pagesLookup;
                    },
                    <{ [pageId: string]: string[] }>{},
                );
                return lookup;
            },
            <IRegistryStructureLookup>{},
        );
        return lookup;
    }

    mergeFilters(
        filters: FilterParam[] | undefined,
        selectedFilterSplits: FilterSplits[],
        selectedDistributionLevel1?: { id: string | undefined; value: string | undefined },
        selectedDistributionLevel2?: { id: string | undefined; value: string | undefined },
    ): FilterParams | undefined {
        const filtersWithSplit = filters ? [...filters] : [];
        selectedFilterSplits?.forEach(item => {
            if (item.filterSplitValue) {
                filtersWithSplit?.push({
                    id: item.filterSplitId,
                    type: "category",
                    values: [item.filterSplitValue],
                });
            }
        });
        if (
            selectedDistributionLevel1?.id &&
            selectedDistributionLevel1?.id !== EMPTY_PARAMETER &&
            selectedDistributionLevel1?.value &&
            selectedDistributionLevel1?.value !== EMPTY_PARAMETER
        ) {
            filtersWithSplit?.push({
                id: selectedDistributionLevel1.id,
                type: "category",
                values: [selectedDistributionLevel1.value],
            });
        }
        if (
            selectedDistributionLevel2?.id &&
            selectedDistributionLevel2?.id !== EMPTY_PARAMETER &&
            selectedDistributionLevel2?.value &&
            selectedDistributionLevel2?.value !== EMPTY_PARAMETER
        ) {
            filtersWithSplit?.push({
                id: selectedDistributionLevel2.id,
                type: "category",
                values: [selectedDistributionLevel2.value],
            });
        }
        return filtersWithSplit.length ? filtersWithSplit : undefined;
    }
}
