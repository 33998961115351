import { Component, ElementRef, EventEmitter, Input, OnDestroy, Output } from "@angular/core";
import { ScrollDispatcher } from "@angular/cdk/scrolling";
import { Overlay } from "@angular/cdk/overlay";

import { LgOverlayService } from "@logex/framework/ui-core";

import { TimeRange } from "@codman/shared/util-filters";
import { OverlayBase } from "@codman/shared/ui-overlay";

import {
    ITimeRangePickerInitProps,
    ITimeRangePickerSelection,
    SharedTimeRangePickerComponent,
} from "../time-range-picker/time-range-picker.component";
import { timeRangeUrlParam, trendIntervalUrlParam } from "@codman/shared/types";

@Component({
    selector: "codman-time-range-filter",
    templateUrl: "./time-range-filter.component.html",
    styleUrls: ["./time-range-filter.component.scss"],
    standalone: false,
})
export class TimeRangeFilterComponent
    extends OverlayBase<ITimeRangePickerInitProps, ITimeRangePickerSelection>
    implements OnDestroy
{
    @Input() minYear = 0;
    @Input() maxYear = 0;
    @Input() minMonth = 0;
    @Input() maxMonth = 0;
    @Input() range: TimeRange = {
        minYear: null,
        maxYear: null,
        minMonth: null,
        maxMonth: null,
    };

    @Input() registry: string | undefined = undefined;
    @Input() subset: string | undefined = undefined;

    @Output() readonly didChange = new EventEmitter<TimeRange>();

    constructor(
        _elementRef: ElementRef<HTMLElement>,
        _scrollDispatcher: ScrollDispatcher,
        _overlayService: LgOverlayService,
        _overlay: Overlay,
    ) {
        super(
            _elementRef,
            _scrollDispatcher,
            _overlayService,
            _overlay,
            SharedTimeRangePickerComponent,
        );
    }

    ngOnDestroy(): void {
        super._onDestroy();
    }

    _showYearPicker($event?: Event): void {
        $event?.stopPropagation();

        if (this._popupActive) return;

        const result$ = this._showPopup(
            {
                minYear: this.minYear,
                maxYear: this.maxYear,
                minMonth: this.minMonth,
                maxMonth: this.maxMonth,
                selectedMinYear: this.range.minYear,
                selectedMaxYear: this.range.maxYear,
                selectedMinMonth: this.range.minMonth,
                selectedMaxMonth: this.range.maxMonth,
            },
            {
                positions: ["bottom-left", "bottom-center"],
            },
        );

        result$.subscribe({
            next: result => {
                this.range.minYear = result.selectedMinYear ?? null;
                this.range.maxYear = result.selectedMaxYear ?? null;
                this.range.minMonth = result.selectedMinMonth ?? null;
                this.range.maxMonth = result.selectedMaxMonth ?? null;
                localStorage.setItem(
                    "timeRange",
                    JSON.stringify({
                        ...this.range,
                        registry: this.registry,
                        subset: this.subset,
                    }),
                );
                this._emitChange(this.range);
            },
            complete: () => this._hidePopup(),
        });

        this._popupActive = true;
    }

    _clear(): void {
        this._emitChange({
            minYear: null,
            maxYear: null,
            minMonth: null,
            maxMonth: null,
        });
        localStorage.removeItem(trendIntervalUrlParam);
        localStorage.removeItem(timeRangeUrlParam);
    }

    private _emitChange(range: TimeRange): void {
        this.didChange.emit(range);
    }
}
