import { Pipe, PipeTransform } from "@angular/core";

import { checkSectionLocation, ISectionLocation } from "./location";

export const EMPTY_PARAMETER = "empty";

@Pipe({
    name: "asOutcomeLabel",
    standalone: true,
})
export class OutcomeLabelPipe implements PipeTransform {
    transform(
        outcomeId: string,
        location: ISectionLocation,
        sectionId: string,
        distributionParameter?: string | null,
        variableId?: string | null,
    ): string {
        if (outcomeId && checkSectionLocation(location) && sectionId) {
            const { registryId, subsetId, pageId } = location;
            const base = `Registries.${registryId}.Subsets.${subsetId}.Pages.${pageId}.Sections.${sectionId}.Outcomes`;
            if (
                distributionParameter &&
                distributionParameter !== EMPTY_PARAMETER &&
                variableId &&
                variableId !== EMPTY_PARAMETER
            )
                return `${base}.${distributionParameter}.${variableId}.Label`;
            return `${base}.${outcomeId}.Label`;
        } else {
            console.warn(
                "One of the provided parameters for the asOutcomeLabel pipe is not defined correctly",
            );
            return "";
        }
    }
}
